import React from 'react';
import './App.css';
import Sidebar from './components/sidebar/sidebar';
import Header from './components/header/header';
import Login from './pages/auth/login/login';
// import ForgotPassword from './pages/auth/forgotPassword/forgotPassword';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import AddCustomerDetail from './pages/customer/AddCustomerDetail';
// import EditCustomerDetail from './pages/customer/EditCustomerDetail';
// import CustomerList from './pages/customer/CustomerList';
// import CustomerDetail from './pages/customer/CustomerDetail';
// import AddDesignDetail from './pages/design/AddDesignDetail';
// import EditDesignDetail from './pages/design/EditDesignDetail';
// import Orders from './pages/order/OrderList';
// import OrderDetail from './pages/order/OrderDetail';
// import EditOrders from './pages/order/EditOrderDetail';
// import Design from './pages/design/DesignList';
// import DesignDetail from './pages/design/DesignDetail';
// import AddOrderDetail from './pages/order/AddOrderDetail';
import Dashboard from './pages/dashboard/Dashboard';
import MachineList from './pages/machine/MachineList';
import AddMachine from './pages/machine/AddMachine';
import EditMachine from './pages/machine/EditMachine';
import ViewMachine from './pages/machine/ViewMachine';
import JobList from './pages/job/JobList';
import AddJob from './pages/job/AddJob';
import EditJob from './pages/job/EditJob';
import ViewJob from './pages/job/ViewJob';
import PartyList from './pages/party/PartyList';
import AddParty from './pages/party/AddParty';
import EditParty from './pages/party/EditParty';
import ViewParty from './pages/party/ViewParty';
import PendingJobList from './pages/pendingJob/PendingJobList';
import CompletedJobList from './pages/completedJob/CompletedJobList';
import Profile from './pages/profile/Profile';
// import Report from './pages/report/report';
// import AddPaymentDetail from './pages/payment/AddPaymentDetails';
// import EditPaymentDetail from './pages/payment/EditPaymentDetails';
// import PaymentDetail from './pages/payment/PaymentDetail';
// import Payment from './pages/payment/PaymentList';
import Logout from './pages/auth/logout/Logout';
// import MultiLayerOrderForm  from  './pages/order/MultilayerOrderForm'
// import PaymentReport from './pages/payment/PaymentReport';
// import AddStaff from './pages/staff/addStaff';
// import Ledger from "./pages/ledger/AddLedgerDetails";
// import Role from './pages/role/RoleList';
// import AddRole from './pages/role/AddRole';
// import EditRole from './pages/role/EditRole';

function App() {
  //if (!localStorage.getItem("AisLogin", false)) {
    return (
      <div className="App">
        <BrowserRouter basename={'/portal'}>
             
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/machine" element={<MachineList />} />
            <Route path="/addMachine" element={<AddMachine />} />
            <Route path="/machine/edit/:id" element={<EditMachine />} /> 
            <Route path="/machine/view/:id" element={<ViewMachine />} />
            <Route path="/job" element={<JobList />} />
            <Route path="/addJob" element={<AddJob />} />
            <Route path="/job/edit/:id" element={<EditJob />} /> 
            <Route path="/job/view/:id" element={<ViewJob />} />
            <Route path="/party" element={<PartyList />} />
            <Route path="/addParty" element={<AddParty />} />
            <Route path="/party/edit/:id" element={<EditParty />} /> 
            <Route path="/party/view/:id" element={<ViewParty />} />
            <Route path="/completedJob" element={<CompletedJobList />} />
            <Route path="/pendingJob" element={<PendingJobList />} />
            <Route path="/profile" element={<Profile/>} />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </BrowserRouter>
      </div>
    )
 // } else {
    // return (
    //   <div className="App">
    //     <BrowserRouter basename={'/RPPortal'}>
    //       <Header />
    //       <Sidebar />
    //       <Routes>
          
    //         <Route path="/dashboard" element={<Dashboard />} />
    //         {/* <Route path="/forgotPassword" element={<ForgotPassword />} />
    //         <Route path="/addCustomer" element={<AddCustomerDetail />} />    
    //         <Route path="/customer/edit/:id" element={<EditCustomerDetail />} />       
    //         <Route path="/customer" element={<CustomerList />} />
    //         <Route path="//customer/view/:id" element={<CustomerDetail />} />
    //         <Route path="/addDesign" element={<AddDesignDetail />} /> 
    //         <Route path="/design/edit/:id" element={<EditDesignDetail />} /> 
    //         <Route path="/design/view/:id" element={<DesignDetail />} />       
    //         <Route path="/design" element={<Design />} />
    //         <Route path="/orders" element={<Orders />} />
    //         <Route path="/addOrder" element={<AddOrderDetail />} /> 
    //         <Route path="/order/edit/:id" element={<EditOrders />} /> 
    //         <Route path="/order/view/:id" element={<OrderDetail />} />   
    //         <Route path="/multiLayerOrderForm" element={<MultiLayerOrderForm />} />      
    //         <Route path="/report" element={<Report />} />          
    //         <Route path="/logout" element={<Logout />} />
    //         <Route path="/addPayment" element={<AddPaymentDetail />} />
    //         <Route path="/payment/edit/:id" element={<EditPaymentDetail />} /> 
    //         <Route path="/payment/view/:id" element={<PaymentDetail />} />    
    //         <Route path="/payment" element={<Payment />} />
    //         <Route path="/paymentReport" element={<PaymentReport />} />
    //         <Route path="/addStaff" element={<AddStaff />} />
    //         <Route path="/ledger" element={<Ledger />} />
    //         <Route path="/role" element={<Role />} />
    //         <Route path="/role/add" element={<AddRole />} />
    //         <Route path="/role/edit/:id" element={<EditRole />} /> */}
    //       </Routes>
    //     </BrowserRouter>
    //   </div>
    // )
 // }
}

export default App;
