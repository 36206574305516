import React, { useEffect, useState } from "react";
import $ from "jquery";
import { getCompletedJobList } from "../../api/apiHandler";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import FeatherIcon from "feather-icons-react";
// import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
// import Switch from '@material-ui/core/Switch';
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import { useNavigate, NavLink } from "react-router-dom";
import "jquery/dist/jquery.min.js";
import Sidebar from "../../components/sidebar/sidebar";
import Header from "../../components/header/header";
import "../../components/sidebar/sidebar.css";
import { MdEdit } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";

function CompletedJobList() {
  const [permissionData, setPermissionData] = useState([]);

  const navigate = useNavigate();
  const [completedJobList, setCompletedJobList] = useState(null);

  useEffect(() => {
    $("#mangeUser").DataTable({
      pagingType: "full_numbers",
      pageLength: 10,
      processing: true,
      order: [[0, "desc"]],
      dom: "Bfrtip",
      buttons: ["copy", "csv", "print"],
      bDestroy: false,
    });
  }, [completedJobList]);

  // const showMessage = (msg) => {
  //     Swal.fire({
  //         position: 'top-end',
  //         icon: 'success',
  //         title: msg,
  //         toast: true,
  //         showConfirmButton: false,
  //         timer: 1500
  //     })
  // }
  const addJobDetails = () => {
    navigate(`/addJob`);
  };
  const editJobDetails = (item) => {
    navigate(`/job/edit/${item}`);
  };
  const viewJobDetails = (item) => {
    navigate(`/job/view/${item}`);
  };

  useEffect(() => {
    Swal.fire({
      title: "Please wait...",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    getCompletedJobList({}).then((response) => {
      console.log(response.data.data);
      if (response.code == 200) {
        Swal.close();
        setCompletedJobList(response.data);
      } else {
        Swal.close();
        setCompletedJobList([]);
      }
    });
  }, []);

  return (
    <>
      <Sidebar />
      <div className="content-page mt-5">
        <div className="content">
          <div className="container-fluid">
            <div
              className="col-lg-10 col-md-8 col-sm-6 offset-2 offset-2"
              style={{ paddingTop: "8px" }}
            >
              <h1 className="offset-3" style={{ marginBottom: "80px" }}>
                Completed Job Details
              </h1>

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col-lg-4">Party Name</th>
                    <th scope="col">Job Name</th>
                    <th scope="col">Corrugation Date</th>
                    <th scope="col">Pasting Date</th>
                    <th scope="col">Punching Date</th>
                    <th scope="col">teaching Date</th>
                    <th scope="col">Glue Date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {completedJobList &&
                    completedJobList.map((item, index) => (
                      <tr>
                        <th scope="row">{item?.id}</th>
                        <td>{item?.party_data?.party_name}</td>
                        <td>{item?.name}</td>
                        <td>{item?.corrugation_starting_date}</td>
                        <td>{item?.pasting_date}</td>
                        <td>{item?.punching_date}</td>
                        <td>{item?.teaching_date}</td>
                        <td>{item?.glue_date}</td>
                        <td>
                          <span
                            onClick={() => {
                              editJobDetails(item?.name);
                            }}
                          >
                            <MdEdit />
                          </span>
                          <span
                            onClick={() => {
                              viewJobDetails(item?.name);
                            }}
                          >
                            {" "}
                            <IoMdEye />{" "}
                          </span>
                          <span
                            onClick={() => {
                              viewJobDetails(item?.name);
                            }}
                          >
                            <MdDeleteOutline />
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompletedJobList;
