import React, { useEffect, useState } from "react";
import { useNavigate,NavLink  } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "../customer/Customer.css";
import "./machine.css";
import Sidebar from '../../components/sidebar/sidebar';
import Header from '../../components/header/header';
import { MdEdit } from "react-icons/md";
import {addMachine} from "../../api/apiHandler";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { yupResolver } from '@hookform/resolvers/yup';
// import Select from "react-select";

function AddMachine() {

  
  const validationSchema = Yup.object().shape({
    machine_number: Yup.string().required("Machine number is required"),
    name: Yup.string().required("Machine name is required"),
    category:Yup.string().required("Category is required"),
    image:Yup.string().required("Machine image is required")
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);

  const { errors } = formState;
  const navigate = useNavigate();
  function redirect() {
    navigate("/machine");
  }
  const onSubmit = (data) => {
    addMachine({
      machine_number: data.machine_number,
      name: data.name,
      category: data.category,
      image:  data.image,
      
    }).then((response) => {
      if (response.code == 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          navigate("/machine");
        }, 2000);
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: response.data.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };
  return (
   <>
    <Sidebar/>
    
    <div className="content-page mt-5 offset-3">
      <div className="content">
        <div className="container-fluid">
          <ToastContainer />
          <div
            className="cal col-xs-2 col-md-6 col-sm-4 col-lg-10"
            style={{ border: "1px solid black" }}
          >
            <h5>Add Machine Details</h5>
            <hr></hr>
            {
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label> Machine Number</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      id="machine_number"       
                      {...register("machine_number")}    
                      placeholder="Please enter machine number "
                      
                    />
                    <div className="invalid-feedback">
                    {errors.machine_number?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label> Machine Image</label>
                  <div>
                    <input
                      type="file"
                      className="form-control"
                      {...register("image")}              
                    />
                    <div className="invalid-feedback">
                    {errors.image?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Machine Name</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      {...register("name")}  
                      placeholder="Please enter machine name "
                      
                    />
                    <div className="invalid-feedback">
                    {errors.name?.message}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label> Category </label>
                  <div className="col-md-12 col-sm-12 col-xs-12 col-lg-4 select">
                    <select className="form-control" {...register("category")}  color="black" placeholder="Please enter machine name ">
                      <option selected value="">
                        Select type
                      </option>
                      <option value="Corrugation1">Corrugation-1</option>
                      <option value="Corrugation2">Corrugation-2</option>
                      <option value="Corrugation3">Corrugation-3</option>
                      <option value="Corrugation4">Corrugation-4</option>
                      <option value="Corrugation5">Corrugation-5</option>
                      <option value="Pasting1">Pasting-1</option>
                      <option value="Pasting2">Pasting-2</option>
                      <option value="Pasting3">Pasting-3</option>
                      <option value="Pasting4">Pasting-4</option>
                      <option value="Pasting5">Pasting-5</option>
                      <option value="Pasting6">Pasting-6</option>
                      <option value="Pasting7">Pasting-7</option>
                      <option value="Pasting8">Pasting-8</option>
                      <option value="Punching1">Punching-1</option>
                      <option value="Punching2">Punching-2</option>
                      <option value="Punching3">Punching-3</option>
                      <option value="Punching4">Punching-4</option>
                      <option value="Punching5">Punching-5</option>
                      <option value="Punching6">Punching-6</option>
                      <option value="Teaching1">Teaching-1</option>
                      <option value="Teaching2">Teaching-2</option>
                      <option value="Teaching3">Teaching-3</option>
                      <option value="Teaching4">Teaching-4</option>
                      <option value="Teaching5">Teaching-5</option>
                      <option value="Teaching6">Teaching-6</option>
                      <option value="Teaching7">Teaching-7</option>
                      <option value="Glue1">Glue-1</option>
                      <option value="Glue2">Glue-2</option>
                      <option value="Glue3">Glue-3</option>
                    </select>
                    <div className="invalid-feedback">
                    {errors.category?.message}
                    </div>
                  </div>
                </div>
                {/* <div className="form-group">
                        <label>  Owner Name</label>
                        <div>
                            <input type="text" className="form-control" {...register('owner_name')} placeholder="Please enter owner name" />
                            
														<div className="invalid-feedback">{errors.owner_name?.message}</div>
                        </div>

                    </div>
                    <div className="form-group">
                        <label>  Owner Number</label>
                        <div>
                            <input type="text" className="form-control" id="owner_number" {...register('owner_number')} placeholder="Please enter owner number" />
                            <div className="invalid-feedback"></div>
                        </div><div className="invalid-feedback">{errors.owner_number?.message}</div>
                    </div> */}
                {/* {inputList.map((x, i) => {
                        return (
                        <div className="box">
													<div className="form-group">
															<label>  Owner Name</label>
															<div>
																	<input type="text" className="form-control" {...register('owner_name')} id="owner_name"  name="owner_name"
					placeholder="Owner Name"
									value={x.ownerName}
																		onChange={e => handleInputChange(e, i)}/>
																	<div className="invalid-feedback">{errors.owner_name?.message}</div>
															</div>
													</div>
													<div className="form-group">
															<label>  Owner Number</label>
															<div>
																	<input type="number"  id="owner_number" {...register('owner_number')} className="form-control" 
									name="owner_number"			placeholder="Owner Number"	value={x.owner_number}		onChange={e => handleInputChange(e, i)} />
																	<div className="invalid-feedback">{errors.owner_number?.message}</div>
															</div>
													</div> */}
                {/* <input type="text" className="form-control" 
                            name="ownerName"
                placeholder="Owner Name"
                            value={x.ownerName}
                            onChange={e => handleInputChange(e, i)}
                            />            
                            <input
                            type="text" className="form-control ml10" 
                            name="ownerNumber"
                placeholder="Owner Number"
                            value={x.ownerNumber}
                            onChange={e => handleInputChange(e, i)}
                            /> */}
                {/* <div className="btn-box">
                            {inputList.length !== 1 && <button
                                className="mr10"
                                onClick={() => handleRemoveClick(i)}>Remove</button>}
                            {inputList.length - 1 === i && <button onClick={handleAddClick}>Add</button>}
                            </div>
                        </div>
                        );
                    })} */}
                {/* {ownerNameInputList.map((x, i) => {
                  return (
                    <div className="box">
                      <div className="form-group">
                        <label> Owner Name</label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            required
                            id="owner_name"
                            name="owner_name"
                            placeholder="Owner Name"
                            value={x.owner_name}
                            onChange={(e) => handleInputChange(e, i)}
                            pattern="^[a-zA-Z_ ]*$"
                            title="Numbers not allow"
                          />
                          <div className="invalid-feedback">
                            {errors.owner_name?.message}
                          </div>
                        </div>
                      </div>
                      <div className="btn-box">
                        {ownerNameInputList.length !== 1 && (
                          <button
                            className="mr10 btn btn-primary  waves-light m-2"
                            onClick={() => handleRemoveClick(i)}
                          >
                            Remove
                          </button>
                        )}
                        {ownerNameInputList.length - 1 === i && (
                          <button
                            className=" mr10 btn btn-primary waves-effect waves-light m-2"
                            onClick={handleAddClick}
                          >
                            Add More
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })} */}
                {/* {ownerNumberInputList.map((x, i) => {
                  return (
                    <div className="box">
                      <div className="form-group">
                        <label> Owner Number</label>
                        <div style={{ display: "flex" }} class="input-box ">
                          <span class="prefix">0</span>
                          <input
                            type="tel"
                            maxLength={10}
                            required
                            className="form-control"
                            id="owner_number"
                            name="owner_number"
                            placeholder="Owner Number"
                            value={x.owner_number}
                            pattern="[0-9]+"
                            title="please enter number only"
                            defaulValue=""
                            onChange={(e) => handleNumberInputChange(e, i)}
                          />
                        </div>
                        <div className="invalid-feedback">
                          {errors.owner_number?.message}
                        </div>
                      </div>

                      <div className="btn-box">
                        {ownerNumberInputList.length !== 1 && (
                          <button
                            className="mr10 btn btn-primary waves-effect waves-light m-2"
                            onClick={() => handleNumberRemoveClick(i)}
                          >
                            Remove
                          </button>
                        )}
                        {ownerNumberInputList.length - 1 === i && (
                          <button
                            className=" mr10 btn btn-primary waves-effect waves-light m-2"
                            onClick={handleNumberAddClick}
                          >
                            Add More
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })} */}

                {/* <div className="form-group">
                  <label> Sector </label>
                  <div className="col-md-12 col-sm-12 col-xs-12 select">
                    <select className="form-control" {...register("sector")}>
                      <option selected value="">
                        Select type
                      </option>
                      <option value="Dairy">Dairy</option>
                      <option value="Lime">Lime</option>
                      <option value="Pepsi">Pepsi</option>
                      <option value="Other">Other</option>
                      <option value="Bearing">Bearing</option>
                      <option value="Cables">Cables</option>
                      <option value="Health Care">Health Care</option>
                    </select>
                    <div className="invalid-feedback">
                      {errors.sector?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label> Address</label>
                  <div>
                    <input
                      type="textarea"
                      className="form-control"
                      {...register("address")}
                      id="address"
                      placeholder="Please enter address"
                    />
                    <div className="invalid-feedback">
                      {errors.address?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label> Credit Period(Days)</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      {...register("credit_period")}
                      id="credit_period"
                      placeholder="Credit Period"
                    />
                    <div className="invalid-feedback">
                      {errors.credit_period?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label> Cash Discount(%)</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      {...register("cash_discount")}
                      id="cash_discount"
                      placeholder="Cash Discount"
                    />
                    <div className="invalid-feedback">
                      {errors.cash_discount?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label> Conversion Rate</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      {...register("conversion_rate")}
                      id="conversion_rate"
                      placeholder="Conversation Rate"
                    />
                    <div className="invalid-feedback">
                      {errors.conversion_rate?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label> Designated Transport</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      {...register("designated_transport")}
                      id="designated_transport"
                      placeholder="Designated Transport"
                      pattern="^[a-zA-Z, ]*$"
                      title="Numbers not allow"
                    />
                    <div className="invalid-feedback">
                      {errors.designated_transport?.message}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label> Transport </label>
                  <div className="col-md-12 col-sm-12 col-xs-12 select">
                    <select className="form-control" {...register("transport")}>
                      <option selected value="">
                        Select type
                      </option>
                      <option value="To Pay">To Pay</option>
                      <option value="Paid">Paid</option>
                      <option value="Partial">Partial</option>
                    </select>
                    <div className="invalid-feedback">
                      {errors.transport?.message}
                    </div>
                  </div>
                </div> */}
                {/* <div className="form-group">
                                    <label>Influencer Company Name</label>
                                    {
                                    <div className="col-md-12 col-sm-12 col-xs-12 select">
                                        <select  className="form-control"  {...register('influencer_company_name')} isMulti isSearchable={true} onChange={handleSelectCustomer}>
                                        {
                                            //CustomerDetailsList && CustomerDetailsList.map((item, index) =>
                                                <option  id={item.id} value={item?.company_name}>{item?.company_name}</option>)
                                        }
                                        </select>
                                    </div>
                                    }
                                </div>
																
                     <div className="form-group">
                        <div>
                        {
                          CustomerDetailsId?.owner_name.split(",").map((item1, index)=>    index == 0 &&
                          <input type="text" className="form-control" {...register('influencer_owner_name')} placeholder='Infulencer Owner Name'  readonly value={item1}/>
                         )}
                        </div>
                    </div>
                    <div className="form-group">
                        <div>
                        {
                          CustomerDetailsId?.owner_number.split(",").map((item, index)=>    index == 0 &&
                            <input type="number" className="form-control" {...register('influencer_owner_number')} placeholder='Infulencer Owner number'  readOnly value={item}/>
                            )}
                       
                        </div>
                    </div> */}
                {/* <label>Influencer Company Name</label>
                {CustomerDetailsList != null ? (
                  <Select
                    {...register("influencer_company_name")}
                    options={optionList}
                    placeholder="Select Infulencer Company"
                    value={selectedOptions}
                    onChange={handleSelect}
                    isSearchable={true}
                    isMulti
                  />
                ) : (
                  <p>Company Not found</p>
                )}

                <div className="form-group">
                  <label>Notes</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      {...register("note")}
                      id="cashdiscount"
                      placeholder="Notes"
                    />
                    <div className="invalid-feedback"></div>
                  </div>
                </div> */}

                <div className="form-group">
                  <button type="submit" className="btn btn-primary m-2">
                    Add
                  </button>
                  <button className="btn btn-danger"  onClick={redirect}>
                    Cancel
                  </button>
                </div>
              </form>
            }
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default AddMachine;
