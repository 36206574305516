import React, { useEffect, useState } from "react";
import { useNavigate,NavLink  } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "../customer/Customer.css";
import Sidebar from '../../components/sidebar/sidebar';
import Header from '../../components/header/header';
import {
  addJob,getPartyList
} from "../../api/apiHandler";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { MdArrowDropDown } from "react-icons/md";
import { yupResolver } from '@hookform/resolvers/yup';

// import Select from "react-select";

function AddJob() {
  const [partyDetailsList, setPartyDetailsList] = useState(null)
  const validationSchema = Yup.object().shape({
    party_id: Yup.string().required("Please select party"),
    name: Yup.string().required("Name is required"),
    sheet_size: Yup.string().required("Sheet Size is required"),
    price: Yup.string().required("Sheet Price is required"),
    incoming_date: Yup.string().required("Please select date"),
    printing_type: Yup.string().required("Please select Printing Type"),
    paper_type: Yup.string().required(
      "Please select Paper Type"
    ),
    paper_size: Yup.string().required("paper size is required"),
    paper_quantity: Yup.string().required("paper quantity is required"),
    lamination: Yup.string().required("lamination is required"),
    paper_side: Yup.string().required("paper side is required"),
    paper_coating: Yup.string().required("paper coating is required"),
    machine_quantity: Yup.string().required("machine_quantity is required"),
    machine_type: Yup.string().required("machine type is required"),
    machine_ply: Yup.string().required("machine ply is required"),
    machine_size: Yup.string().required("machine size is required"),
    machine_flute: Yup.string().required("machine flute is required"),
    machine_gsm: Yup.string().required("machine gsm is required"),
    corrugation_starting_date: Yup.string().required(" corrugation starting date is required"),
    
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);

  const { errors } = formState;
  
  const navigate = useNavigate();
  const prefix = "0";

  function redirect() {
    navigate("/job");
  }

  useEffect(() => {
    getPartyList({}).then((response) => {
        if (response.code == 200) {
          setPartyDetailsList(response.data)
        }
    })
}, [])

  const onSubmit = (data) => {
    addJob({
     party_id: data.party_id,
    name: data.name,
    sheet_size: data.sheet_size,
    price: data.price,
    incoming_date: data.incoming_date,
    printing_type: data.printing_type,
    paper_type: data.paper_type,
    paper_size: data.paper_size,
    paper_quantity: data.paper_quantity,
    lamination: data.lamination,
    paper_side: data.paper_side,
    paper_coating: data.paper_coating,
    machine_quantity: data.machine_quantity,
    machine_type: data.machine_type,
    machine_ply: data.machine_ply,
    machine_size:data.machine_size,
    machine_flute: data.machine_flute,
    machine_gsm: data.machine_gsm,
    corrugation_starting_date: data.corrugation_starting_date,
    punching_date: "2024-08-21",
    pasting_date: "2024-08-21",
    teaching_date: "2024-08-21",
    glue_date:  "2024-08-21"
      
    }).then((response) => {
      if (response.code == 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          navigate("/pendingJob");
        }, 2000);
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: response.data.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };
  return (
   <>
    <Sidebar/>
    
    <div className="content-page mt-5 offset-2">
      <div className="content">
        <div className="container-fluid">
          <ToastContainer />
          <div
            className="cal col-xs-2 col-md-6 col-sm-4 col-lg-10"
            style={{ border: "1px solid black" }}
          >
            <h5>Add Job</h5>
            <hr></hr>
            {
              <form onSubmit={handleSubmit(onSubmit)}>
                 <div className="form-group">
                  <label> Party Name </label>
                  <div className="col-md-12 col-sm-12 col-xs-12 select">
                    <select className="form-control" {...register('party_id')} >
                      <option selected value="">
                        Select type
                      </option>
                      {
																				partyDetailsList && partyDetailsList.map((item, index) =>
                      <option value={item?.id}>{item?.party_name}</option>)}
                    </select>
                    <div className="invalid-feedback">
                    {errors.party_id?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label> Job Name</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="company_name"  
                      {...register('name')}                    
                    />
                    <div className="invalid-feedback">
                    {errors.name?.message}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12 col-xs-12 col-lg-6 ">
                    <label>Sheet Size</label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        id="company_name"
                        {...register('sheet_size')}      
                        placeholder="Please Enter "
                        
                      />
                      <div className="invalid-feedback">
                      {errors.sheet_size?.message}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-12 col-sm-12 col-xs-12 col-lg-6 ">
                    <label>Sheet Price</label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        id="company_name"
                        placeholder="Please Enter "
                        {...register('price')}
                      />
                      <div className="invalid-feedback">
                      {errors.price?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12 col-xs-12 col-lg-6">
                    <label>Incoming Date</label>
                    <div>
                      <input
                        type="date"
                        className="form-control"
                        id="company_name"
                        placeholder="Please Enter "
                        {...register('incoming_date')}
                      />
                      <div className="invalid-feedback">
                      {errors.incoming_date?.message}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-12 col-sm-12 col-xs-12 col-lg-6 ">
                    <label> Printing Type</label>
                    <div className="col-md-12 col-sm-12 col-xs-12 select">
                      <select className="form-control" {...register('printing_type')}> 
                        <option selected value="">
                          Select type
                        </option>
                        <option value="1">Streo</option>
                        <option value="2">Obset</option>
                      </select>
                      <div className="invalid-feedback">
                      {errors.printing_type?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12 col-xs-12 col-lg-4">
                    <label>Paper Type</label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        id="company_name"
                        placeholder="Please Enter "
                        {...register('paper_type')}
                        
                      />
                      <div className="invalid-feedback">
                      {errors.paper_type?.message}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-12 col-sm-12 col-xs-12 col-lg-4">
                    <label>Paper  Size</label>
                    <div>
                      <input
                        type="number"
                        className="form-control"
                        id="company_name"
                        placeholder="Please Enter "
                        {...register('paper_size')}
                      />
                      <div className="invalid-feedback">
                      {errors.paper_size?.message}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-12 col-sm-12 col-xs-12 col-lg-4">
                  <label>Paper  Quantity</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      id="company_name"
                      {...register('paper_quantity')}
                      placeholder="Please Enter "  />
                    <div className="invalid-feedback">
                    {errors.paper_quantity?.message}
                    </div>
                  </div>
                </div>
                </div>
                <label>Lamination</label>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12 col-xs-12 col-lg-4 ">
                    
                    <div className="col-md-12 col-sm-12 col-xs-12 select">
                      <select className="form-control" {...register('lamination')}>                        
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                      <span><MdArrowDropDown/></span>
                      <div className="invalid-feedback">
                      {errors.lamination?.message}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-12 col-sm-12 col-xs-12 col-lg-4 ">
                    <div className="col-md-12 col-sm-12 col-xs-12 select">
                      <select className="form-control" {...register('paper_side')}>                       
                        <option value="1">Front</option>
                        <option value="2">Back</option>
                        <option value="3">Front/Back</option>
                      </select>
                      <div className="invalid-feedback">
                      {errors.paper_side?.message}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-12 col-sm-12 col-xs-12 col-lg-4 ">
                    <div className="col-md-12 col-sm-12 col-xs-12 select">
                      <select className="form-control" {...register('paper_coating')}>                       
                        <option value="Dairy">Gloss</option>
                        <option value="Lime">Met</option>
                        <option value="Lime">Uv</option>
                      </select>
                      <div className="invalid-feedback">
                      {errors.paper_coating?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <label>Machine Type-Corrugation</label>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12 col-xs-12 col-lg-4">
                    <label>Quantity</label>
                    <div>
                      <input
                        type="number"
                        className="form-control"
                        id="company_name"
                        {...register('machine_quantity')}
                        placeholder="Please Enter "
                        
                      />
                      <div className="invalid-feedback">
                      {errors.machine_quantity?.message}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-12 col-sm-12 col-xs-12 col-lg-4 ">
                    <label> Type</label>
                    <div className="col-md-12 col-sm-12 col-xs-12 select">
                      <select className="form-control"  {...register('machine_type')}>                       
                        <option value="1">Narrow</option>
                        <option value="2">Eflute</option>
                      </select>
                      <div className="invalid-feedback">
                      {errors.machine_type?.message}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-12 col-sm-12 col-xs-12 col-lg-4 ">
                  <label>Ply</label>
                    <div className="col-md-12 col-sm-12 col-xs-12 select" {...register('machine_ply')}>
                      <select className="form-control">                       
                        <option value="3">3</option>
                        <option value="5">5</option>
                        <option value="7">7</option>
                        <option value="9">9</option>
                      </select>
                      <div className="invalid-feedback">
                      {errors.machine_ply?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12 col-xs-12 col-lg-4">
                    <label>Size</label>
                    <div>
                      <input
                        type="number"
                        className="form-control"
                        id="company_name"
                        placeholder="Please Enter "
                        {...register('machine_size')}
                        
                      />
                      <div className="invalid-feedback">
                      {errors.machine_size?.message}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-12 col-sm-12 col-xs-12 col-lg-4">
                    <label>FLUTE</label>
                    <div>
                      <input
                        type="number"
                        className="form-control"
                        id="company_name"
                        placeholder="Please Enter "
                        {...register('machine_flute')}
                      />
                      <div className="invalid-feedback">
                      {errors.machine_flute?.message}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-12 col-sm-12 col-xs-12 col-lg-4">
                    <label>GSM</label>
                    <div>
                      <input
                        type="number"
                        className="form-control"
                        id="company_name"
                        placeholder="Please Enter "
                        {...register('machine_gsm')}
                      />
                      <div className="invalid-feedback">
                      {errors.machine_gsm?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Corrugation Starting Date</label>
                  <div>
                    <input
                      type="date"
                      className="form-control"
                      id="company_name"
                      placeholder="Please Enter "
                      {...register('corrugation_starting_date')}
                    />
                    <div className="invalid-feedback">
                    {errors.corrugation_starting_date?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary m-2">
                    Add
                  </button>
                  <button className="btn btn-danger"  onClick={redirect}>
                    Cancel
                  </button>
                </div>
              </form>
            }
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default AddJob;
