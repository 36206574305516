export function loginRedirectCall() {
  let path =
    window.location.protocol + "//" + window.location.host + "/portal/";
  // window.open(path,'mywindow').focus()
  console.log(path);
  window.location.href = path;
}
export function logOutRedirectCall() {
  localStorage.removeItem("AisLogin");
  localStorage.removeItem("Aid");
  localStorage.removeItem("Atoken");
  localStorage.removeItem("Aemail");
  localStorage.removeItem("Acountry_code");
  localStorage.removeItem("Aphone");
  localStorage.removeItem("Afirst_name");
  localStorage.removeItem("Alast_name");

  loginRedirectCall();
}

export function loginStoreData(data) {
  localStorage.setItem("AisLogin", true);
  localStorage.setItem("Atoken", data.token);
  localStorage.setItem("Aid", data.id);
  localStorage.setItem("Aemail", data.email);
  localStorage.setItem("Acountry_code", data.country_code);
  localStorage.setItem("Aphone", data.phone);
  localStorage.setItem("Afirst_name", data.first_name);
  localStorage.setItem("Alast_name", data.last_name);
  localStorage.setItem("Arole", data.role);
  localStorage.setItem("orderStatus", "All");
  // loginRedirectCall()
}
