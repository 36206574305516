import { axiosClient } from "./apiClient";

export function login(data){
    return axiosClient.post('login', data);
}
export function dashboard(data){
    return axiosClient.get('get-dashborad-count', data);
}
//Party
export function getPartyList(data){
    return axiosClient.get('partys-list', data);
}
export function getParty(data){
    return axiosClient.post('get-party-details', data);
}
export function addParty(data){
    console.log(data)
    return axiosClient.post('add-party-details', data);
}
export function updateParty(data){
    return axiosClient.post('update-party-details', data);
}
export function changePartyStatus(data){
    return axiosClient.post('update-party-status', data);
}
export function deleteParty(data){
    return axiosClient.post('updateCustomer', data);
}


//machine
export function getMachineList(data){
    return axiosClient.get('machines-list', data);
}
export function getMachine(data){
    return axiosClient.post('get-machine-details', data);
}
export function addMachine(data){
    console.log(data)
    return axiosClient.post('add-machine-details', data);
}
export function updateMachine(data){
    return axiosClient.post('update-machine-details', data);
}
export function changeMachineStatus(data){
    return axiosClient.post('update-machine-status', data);
}
export function deleteMachine(data){
    return axiosClient.post('updateCustomer', data);
}


//job
export function getPendingJobList(data){
    return axiosClient.get('pending-jobs-list', data);
}

export function getCompletedJobList(data){
    return axiosClient.get('completed-jobs-list', data);
}

export function getJob(data){
    return axiosClient.post('get-job-details', data);
}
export function addJob(data){
    console.log(data)
    return axiosClient.post('add-job-details', data);
}
export function updateJob(data){
    return axiosClient.post('update-job-details', data);
}
export function changeJobStatus(data){
    return axiosClient.post('update-job-status', data);
}
export function deleteJob(data){
    return axiosClient.post('delete-job', data);
}

export function logout(data){
    return axiosClient.get('logout', data);
}

export function getProfile(data){
    return axiosClient.get('get-profile', data);
}