import React, { useEffect, useState } from "react";
import {
  dashboard
} from "../../api/apiHandler";
import "./Dashboard.css";
import "jquery/dist/jquery.min.js";
import $ from "jquery";
import Sidebar from '../../components/sidebar/sidebar';
import Header from '../../components/header/header';
import '../../components/sidebar/sidebar.css';
import 'react-toastify/dist/ReactToastify.css';
// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
// import "datatables.net-buttons/js/dataTables.buttons.js";
// import "datatables.net-buttons/js/buttons.colVis.js";
// import "datatables.net-buttons/js/buttons.flash.js";
// import "datatables.net-buttons/js/buttons.html5.js";
// import "datatables.net-buttons/js/buttons.print.js";
// import Select from "react-select";

function Dashboard() {
 
  const [totalMachine, setTotalMachine] = useState(null);
  const [totalPartys, setTotalParty] = useState(null);  
  const [totalPendingJob, setTotalPendingJob] = useState(null);
  const [totalCompleteJob, setTotalCompleteJob] = useState(null);
  


  
  useEffect(() => {
    dashboard({}).then((response) => {
      console.log(response.data.code);
      if (response.code == 200) {
        setTotalParty(response.data.total_partys);
        setTotalMachine(response.data.total_machines);
        setTotalPendingJob(response.data.total_pending_jobs);
        setTotalCompleteJob(response.data.total_printing_jobs);
      }
    });
  }, []);

  return (
    <>
    <Sidebar/>
      <main>
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="dashboard">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 offset-2">
                    <div className="page-title-box">
                      <h2 className="page-title">
                        {/* {localStorage.getItem("Afirst_name").toUpperCase()}{" "} */}
                        Dashboard
                      </h2>
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          Welcome to  Portal
                        </li>
                      </ol>
                    </div>
                  </div>
                  
                </div>
                <div className="col-lg-8  col-md-6 col-sm-4 offset-2" style={{padding:'20px'}}>
                  <h3>Data List</h3>
                  <div className="row">
                    <div className="col-lg-3 col-md-4">
                      <div className="card  bg-primary">
                        <div className="card-body mini-stat-img">
                          <div className="mini-stat-icon">
                            <i className="mdi mdi-account-multiple float-right"></i>
                          </div>
                          <div className="text-white">
                            <h6 className="text-uppercase mb-3">Party</h6>
                            <h4 className="mb-4">{totalPartys}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="card  bg-primary">
                        <div className="card-body mini-stat-img">
                          <div className="mini-stat-icon">
                            <i className="mdi mdi-buffer float-right"></i>
                          </div>
                          <div className="text-white">
                            <h6 className="text-uppercase mb-3">
                              Pending Jobs
                            </h6>
                            <h4 className="mb-4">{totalPendingJob}</h4>                         
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="card  bg-primary">
                        <div className="card-body mini-stat-img">
                          <div className="mini-stat-icon">
                            <i className="mdi mdi-buffer float-right"></i>
                          </div>
                          <div className="text-white">
                            <h6 className="text-uppercase mb-3">
                              Completed Jobs
                            </h6>
                            <h4 className="mb-4">{totalCompleteJob}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="card  bg-primary">
                        <div className="card-body mini-stat-img">
                          <div className="mini-stat-icon">
                            <i className="mdi mdi-buffer float-right"></i>
                          </div>
                          <div className="text-white">
                            <h6 className="text-uppercase mb-3">
                              Machines
                            </h6>
                            <h4 className="mb-4">{totalMachine}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6">
                                          <div className="card mini-stat bg-primary">
                                              <div className="card-body mini-stat-img">
                                                  <div className="mini-stat-icon">
                                                      <i className="mdi mdi-tag-text-outline float-right"></i>
                                                  </div>
                                                  <div className="text-white">
                                                      <h6 className="text-uppercase mb-3">Completed Quotes</h6>
                                                      <h4 className="mb-4">30</h4>
                                                  </div>
                                              </div>
                                          </div>
                                      </div> */}
                  </div>
                </div>
                
               
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default Dashboard;
