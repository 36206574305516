import React , { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/sidebar";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Swal from "sweetalert2";
import {
  getProfile
} from "../../api/apiHandler";

const Profile = () => {

  const [profileDetails, setProfileDetails] = useState(null);
 
  useEffect(() => {
    			Swal.fire({
    				title: 'Please wait...',
    				didOpen: () => {
    				Swal.showLoading()
    				}
    			})
    		
    				getProfile({}).then((response) => {
  
    						console.log(response.data.data)
    						if (response.code == 200) {
    							Swal.close()
                  setProfileDetails(response.data)
    						}
    						else
    						{
    							Swal.close()
    							setProfileDetails(null)
    						}
    				})  			
    			
       }, [])
       console.log(profileDetails?.id,"profile")
  return (
    <>
      <Sidebar />
      <h2 style={{ textAlign: "center", marginTop: "3%" }}>Profile </h2>
      <div
        className="form"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        
        <Form>
          <FormGroup>
            <Label for="exampleUsername">User Name</Label>
            <Input
              id="exampleUsername"
              name="username"
              placeholder="enter your username"
             value={profileDetails?.first_name}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleFullname">Full Name</Label>
            <Input
              id="exampleFullname"
              name="fullname"
              placeholder="enter your fullname"   
              value={profileDetails?.first_name+" "+profileDetails?.last_name }            
            />
          </FormGroup>          
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="exampleEmail">Email</Label>
                <Input
                  id="exampleEmail"
                  name="email"
                  placeholder="enter your email"
                  type="email"
                  value={profileDetails?.email}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="examplePassword">Password</Label>
                <Input
                  id="examplePassword"
                  name="password"
                  placeholder="enter your password"
                  type="password"
                  value="123456"
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="examplePhonenumber">Phone Number</Label>
            <Input
              id="examplePhonenumber"
              name="phonenumber"
              placeholder="enter your phone number"
              type="number"
              value="7895462222"
            />
          </FormGroup>
        </Form>
      </div>
    </>
  );
};

export default Profile;
