import React, { useEffect, useState } from "react";
import { useNavigate,NavLink  } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "../customer/Customer.css";
import Sidebar from '../../components/sidebar/sidebar';
import Header from '../../components/header/header';
import {
  addCustomer,
  getCustomer,
  getCustomerById,
  getPermission,
} from "../../api/apiHandler";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
// import Select from "react-select";

function EditParty() {
//   var asyncLoop = require("node-async-loop");
  const [ownerNameInputList, setOwnerNameInputList] = useState([
    { owner_name: "" },
  ]);
  const [permissionData, setPermissionData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [ownerNumberInputList, setOwnerNumberInputList] = useState([
    { owner_number: "" },
  ]);
  const [CustomerDetailsList, setCustomerDetailsList] = useState(null);
  const [CustomerDetailsId, setCustomerDetailsId] = useState(null);
  const [selectValue, setSelectValue] = React.useState("");
  const [customerId, setCustomerId] = React.useState("");
  const [selectedInfluencer, setSelectedInfluencer] = React.useState("");
  function redirect() {
    navigate("/party");
  }
//   const validationSchema = Yup.object().shape({
//     company_name: Yup.string().required("Company name is required"),
//     sector: Yup.string().required("sector is required"),
//     address: Yup.string().required("Address is required"),
//     credit_period: Yup.string().required("Credit period is required"),
//     cash_discount: Yup.string().required("Cash discount is required"),
//     conversion_rate: Yup.string().required("Conversion rate is required"),
//     designated_transport: Yup.string().required(
//       "Designated transport is required"
//     ),
//     transport: Yup.string().required("Transport is required"),
//   });
//   const formOptions = { resolver: yupResolver(validationSchema) };
//   const { register, handleSubmit, formState } = useForm(formOptions);

//   const { errors } = formState;
  const ownernameArray = [];
  const ownernumberArray = [];

  const navigate = useNavigate();
  const prefix = "0";

//   // handle input change
//   const handleInputChange = (e, index) => {
//     const { name, value } = e.target;
//     const list = [...ownerNameInputList];
//     list[index][name] = value;
//     setOwnerNameInputList(list);
//   };
//   // handle click event of the Remove button
//   const handleRemoveClick = (index) => {
//     const list = [...ownerNameInputList];
//     list.splice(index, 1);
//     setOwnerNameInputList(list);
//   };
//   // handle click event of the Add button
//   const handleAddClick = () => {
//     console.log(ownerNameInputList);
//     setOwnerNameInputList([...ownerNameInputList, { owner_name: "" }]);
//   };
//   //owner number
//   // handle input change
//   const handleNumberInputChange = (e, index) => {
//     const { name, value } = e.target;
//     const list = [...ownerNumberInputList];
//     list[index][name] = value;
//     setOwnerNumberInputList(list);
//   };
//   // handle click event of the Remove button
//   const handleNumberRemoveClick = (index) => {
//     const list = [...ownerNumberInputList];
//     list.splice(index, 1);
//     setOwnerNumberInputList(list);
//   };
//   // handle click event of the Add button
//   const handleNumberAddClick = () => {
//     setOwnerNumberInputList([...ownerNumberInputList, { owner_number: "" }]);
//   };

//   // Function triggered on selection
//   function handleSelect(data) {
//     var influencerArray = [];
//     data && data.map((item, index) => influencerArray.push(item?.value));
//     console.log(influencerArray);
//     setSelectedInfluencer(influencerArray);
//     //setSelectedInfluencer(influencerArray)
//   }
//   console.log(selectedOptions);
//   console.log(selectedInfluencer.toString());
//   //infulencers
//   function handleSelectCustomer(event) {
//     const value = event.target.value;
//     const index = event.target.selectedIndex;
//     const el = event.target.childNodes[index];
//     setCustomerId(el.getAttribute("id"));
//     setSelectValue(value);
//   }
//   function redirect() {
//     navigate("/customer");
//   }

//   useEffect(() => {
//     getCustomer({}).then((response) => {
//       console.log(response.data.code);
//       if (response.data.code == 1) {
//         setCustomerDetailsList(response.data.data);
//       }
//     });
//   }, []);
//   console.log(CustomerDetailsList);
//   const optionList =
//     CustomerDetailsList &&
//     CustomerDetailsList.map((item, index) =>
//       item?.status != "Inactive"
//         ? { value: item?.company_name, label: item?.company_name, id: item?.id }
//         : {
//             value: "Company Not found",
//             label: "Company Not found",
//             id: "Company Not found",
//           }
//     );
//   console.log(optionList);

//   useEffect(() => {
//     if (localStorage.getItem("Arole") == "admin") {
//       var adminPermission = ["2"];
//       setPermissionData(adminPermission);
//     } else {
//       getPermission({ staff_id: localStorage.getItem("Aid") }).then((res) => {
//         if (res.data.code == 1) {
//           setPermissionData(res.data.data[0].permission.split(","));
//         }
//       });
//     }
//   }, []);

//   useEffect(() => {
//     if (permissionData.length > 0) {
//       if (permissionData.indexOf("2") == -1) {
//         navigate(`/`);
//       }
//     }
//   }, [permissionData]);
//   // const handleChange = (skills) => {
//   //     setSkills(skills || []);
//   //   };
//   //  const options = [
//   //     // CustomerDetailsList &&  CustomerDetailsList.map((item, index) => (

//   //     //     { value: item?.company_name, label: item?.company_name }
//   //     //     ))

//   // //     { value: "React", label: "React" },
//   // //     { value: "Vue", label: "Vue" },
//   // //     { value: "Angular", label: "Angular" },
//   // //     { value: "Java", label: "Java" }
//   //    ];
//   useEffect(() => {
//     getCustomerById({ customer_id: customerId }).then((resposnse) => {
//       if (resposnse.data.code == 1) {
//         setCustomerDetailsId(resposnse.data.data);
//       }
//     });
//   }, [customerId]);
//   const onSubmit = (data) => {
//     console.log(data);
//     asyncLoop(
//       ownerNameInputList,
//       function (item, next) {
//         console.log(item.owner_name);
//         ownernameArray.push(item.owner_name);
//         next();
//       },
//       function () {
//         asyncLoop(
//           ownerNumberInputList,
//           function (item, next) {
//             ownernumberArray.push("0" + item.owner_number);
//             next();
//           },
//           function () {
//             addCustomer({
//               company_name: data.company_name,
//               owner_name: ownernameArray.toString(),
//               owner_number: ownernumberArray.toString(),
//               sector: data.sector,
//               address: data.address,
//               credit_period: data.credit_period,
//               cash_discount: data.cash_discount,
//               conversion_rate: data.conversion_rate,
//               designated_transport: data.designated_transport,
//               transport: data.transport,
//               influencer_company_name: selectedInfluencer.toString(),
//               //  "influencer_owner_name": data.influencer_owner_name,
//               //   "influencer_owner_number": data.influencer_owner_number,
//               notes: data.note,
//               role: localStorage.getItem("Afirst_name"),
//             }).then((response) => {
//               if (response.data.code == 1) {
//                 Swal.fire({
//                   position: "top-end",
//                   icon: "success",
//                   title: response.data.message,
//                   toast: true,
//                   showConfirmButton: false,
//                   timer: 2000,
//                 });
//                 setTimeout(() => {
//                   navigate("/Customer");
//                 }, 2000);
//               } else {
//                 Swal.fire({
//                   position: "top-end",
//                   icon: "error",
//                   title: response.data.message,
//                   toast: true,
//                   showConfirmButton: false,
//                   timer: 2000,
//                 });
//               }
//             });
//           }
//         );
//       }
//     );
//   };

  return (
   <>
    <Sidebar/>
    
    <div className="content-page mt-5 offset-2">
      <div className="content">
        <div className="container-fluid">
          <ToastContainer />
          <div
            className="cal col-xs-2 col-md-6 col-sm-4 col-lg-10"
            style={{ border: "1px solid black" }}
          >
            <h5>Edit Party Details</h5>
            <hr></hr>
            {
              <form>
              <div className="form-group">
                <label>GST Number</label>
                <div>
                  <input
                    type="number"
                    className="form-control"
                    id="company_name"                      
                  />
                  <div className="invalid-feedback">
                    
                  </div>
                </div>
              </div>  
              <div className="form-group">
                <label> Party Name</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    id="company_name"                      
                  />
                  <div className="invalid-feedback">
                    
                  </div>
                </div>
              </div>  
              <div className="form-group">
                <label> Contact Name</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    id="company_name"                      
                  />
                  <div className="invalid-feedback">
                    
                  </div>
                </div>
              </div>            
              <div className="form-group">
                <label>City</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    id="company_name"                      
                  />
                  <div className="invalid-feedback">
                    
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label> Address</label>
                <div>
                  <input
                    type="textarea"
                    className="form-control"
                    id="address"
                    placeholder="Please enter address"
                  />
                  <div className="invalid-feedback">
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label> PinCode</label>
                <div>
                  <input
                    type="number"
                    className="form-control"
                    id="company_name"  
                    maxLength={6}                    
                  />
                  <div className="invalid-feedback">
                    
                  </div>
                </div>
              </div>
              {/* <div className="form-group">
                      <label>  Owner Name</label>
                      <div>
                          <input type="text" className="form-control" {...register('owner_name')} placeholder="Please enter owner name" />
                          
                          <div className="invalid-feedback">{errors.owner_name?.message}</div>
                      </div>

                  </div>
                  <div className="form-group">
                      <label>  Owner Number</label>
                      <div>
                          <input type="text" className="form-control" id="owner_number" {...register('owner_number')} placeholder="Please enter owner number" />
                          <div className="invalid-feedback"></div>
                      </div><div className="invalid-feedback">{errors.owner_number?.message}</div>
                  </div> */}
              {/* {inputList.map((x, i) => {
                      return (
                      <div className="box">
                        <div className="form-group">
                            <label>  Owner Name</label>
                            <div>
                                <input type="text" className="form-control" {...register('owner_name')} id="owner_name"  name="owner_name"
        placeholder="Owner Name"
                value={x.ownerName}
                                  onChange={e => handleInputChange(e, i)}/>
                                <div className="invalid-feedback">{errors.owner_name?.message}</div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>  Owner Number</label>
                            <div>
                                <input type="number"  id="owner_number" {...register('owner_number')} className="form-control" 
                name="owner_number"			placeholder="Owner Number"	value={x.owner_number}		onChange={e => handleInputChange(e, i)} />
                                <div className="invalid-feedback">{errors.owner_number?.message}</div>
                            </div>
                        </div> */}
              {/* <input type="text" className="form-control" 
                          name="ownerName"
              placeholder="Owner Name"
                          value={x.ownerName}
                          onChange={e => handleInputChange(e, i)}
                          />            
                          <input
                          type="text" className="form-control ml10" 
                          name="ownerNumber"
              placeholder="Owner Number"
                          value={x.ownerNumber}
                          onChange={e => handleInputChange(e, i)}
                          /> */}
              {/* <div className="btn-box">
                          {inputList.length !== 1 && <button
                              className="mr10"
                              onClick={() => handleRemoveClick(i)}>Remove</button>}
                          {inputList.length - 1 === i && <button onClick={handleAddClick}>Add</button>}
                          </div>
                      </div>
                      );
                  })} */}
              {/* {ownerNameInputList.map((x, i) => {
                return (
                  <div className="box">
                    <div className="form-group">
                      <label> Owner Name</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="owner_name"
                          name="owner_name"
                          placeholder="Owner Name"
                          value={x.owner_name}
                          onChange={(e) => handleInputChange(e, i)}
                          pattern="^[a-zA-Z_ ]*$"
                          title="Numbers not allow"
                        />
                        <div className="invalid-feedback">
                          {errors.owner_name?.message}
                        </div>
                      </div>
                    </div>
                    <div className="btn-box">
                      {ownerNameInputList.length !== 1 && (
                        <button
                          className="mr10 btn btn-primary  waves-light m-2"
                          onClick={() => handleRemoveClick(i)}
                        >
                          Remove
                        </button>
                      )}
                      {ownerNameInputList.length - 1 === i && (
                        <button
                          className=" mr10 btn btn-primary waves-effect waves-light m-2"
                          onClick={handleAddClick}
                        >
                          Add More
                        </button>
                      )}
                    </div>
                  </div>
                );
              })} */}
              {/* {ownerNumberInputList.map((x, i) => {
                return (
                  <div className="box">
                    <div className="form-group">
                      <label> Owner Number</label>
                      <div style={{ display: "flex" }} class="input-box ">
                        <span class="prefix">0</span>
                        <input
                          type="tel"
                          maxLength={10}
                          required
                          className="form-control"
                          id="owner_number"
                          name="owner_number"
                          placeholder="Owner Number"
                          value={x.owner_number}
                          pattern="[0-9]+"
                          title="please enter number only"
                          defaulValue=""
                          onChange={(e) => handleNumberInputChange(e, i)}
                        />
                      </div>
                      <div className="invalid-feedback">
                        {errors.owner_number?.message}
                      </div>
                    </div>

                    <div className="btn-box">
                      {ownerNumberInputList.length !== 1 && (
                        <button
                          className="mr10 btn btn-primary waves-effect waves-light m-2"
                          onClick={() => handleNumberRemoveClick(i)}
                        >
                          Remove
                        </button>
                      )}
                      {ownerNumberInputList.length - 1 === i && (
                        <button
                          className=" mr10 btn btn-primary waves-effect waves-light m-2"
                          onClick={handleNumberAddClick}
                        >
                          Add More
                        </button>
                      )}
                    </div>
                  </div>
                );
              })} */}

              {/* <div className="form-group">
                <label> Sector </label>
                <div className="col-md-12 col-sm-12 col-xs-12 select">
                  <select className="form-control" {...register("sector")}>
                    <option selected value="">
                      Select type
                    </option>
                    <option value="Dairy">Dairy</option>
                    <option value="Lime">Lime</option>
                    <option value="Pepsi">Pepsi</option>
                    <option value="Other">Other</option>
                    <option value="Bearing">Bearing</option>
                    <option value="Cables">Cables</option>
                    <option value="Health Care">Health Care</option>
                  </select>
                  <div className="invalid-feedback">
                    {errors.sector?.message}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label> Address</label>
                <div>
                  <input
                    type="textarea"
                    className="form-control"
                    {...register("address")}
                    id="address"
                    placeholder="Please enter address"
                  />
                  <div className="invalid-feedback">
                    {errors.address?.message}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label> Credit Period(Days)</label>
                <div>
                  <input
                    type="number"
                    className="form-control"
                    {...register("credit_period")}
                    id="credit_period"
                    placeholder="Credit Period"
                  />
                  <div className="invalid-feedback">
                    {errors.credit_period?.message}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label> Cash Discount(%)</label>
                <div>
                  <input
                    type="number"
                    className="form-control"
                    {...register("cash_discount")}
                    id="cash_discount"
                    placeholder="Cash Discount"
                  />
                  <div className="invalid-feedback">
                    {errors.cash_discount?.message}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label> Conversion Rate</label>
                <div>
                  <input
                    type="number"
                    className="form-control"
                    {...register("conversion_rate")}
                    id="conversion_rate"
                    placeholder="Conversation Rate"
                  />
                  <div className="invalid-feedback">
                    {errors.conversion_rate?.message}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label> Designated Transport</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    {...register("designated_transport")}
                    id="designated_transport"
                    placeholder="Designated Transport"
                    pattern="^[a-zA-Z, ]*$"
                    title="Numbers not allow"
                  />
                  <div className="invalid-feedback">
                    {errors.designated_transport?.message}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label> Transport </label>
                <div className="col-md-12 col-sm-12 col-xs-12 select">
                  <select className="form-control" {...register("transport")}>
                    <option selected value="">
                      Select type
                    </option>
                    <option value="To Pay">To Pay</option>
                    <option value="Paid">Paid</option>
                    <option value="Partial">Partial</option>
                  </select>
                  <div className="invalid-feedback">
                    {errors.transport?.message}
                  </div>
                </div>
              </div> */}
              {/* <div className="form-group">
                                  <label>Influencer Company Name</label>
                                  {
                                  <div className="col-md-12 col-sm-12 col-xs-12 select">
                                      <select  className="form-control"  {...register('influencer_company_name')} isMulti isSearchable={true} onChange={handleSelectCustomer}>
                                      {
                                          //CustomerDetailsList && CustomerDetailsList.map((item, index) =>
                                              <option  id={item.id} value={item?.company_name}>{item?.company_name}</option>)
                                      }
                                      </select>
                                  </div>
                                  }
                              </div>
                              
                   <div className="form-group">
                      <div>
                      {
                        CustomerDetailsId?.owner_name.split(",").map((item1, index)=>    index == 0 &&
                        <input type="text" className="form-control" {...register('influencer_owner_name')} placeholder='Infulencer Owner Name'  readonly value={item1}/>
                       )}
                      </div>
                  </div>
                  <div className="form-group">
                      <div>
                      {
                        CustomerDetailsId?.owner_number.split(",").map((item, index)=>    index == 0 &&
                          <input type="number" className="form-control" {...register('influencer_owner_number')} placeholder='Infulencer Owner number'  readOnly value={item}/>
                          )}
                     
                      </div>
                  </div> */}
              {/* <label>Influencer Company Name</label>
              {CustomerDetailsList != null ? (
                <Select
                  {...register("influencer_company_name")}
                  options={optionList}
                  placeholder="Select Infulencer Company"
                  value={selectedOptions}
                  onChange={handleSelect}
                  isSearchable={true}
                  isMulti
                />
              ) : (
                <p>Company Not found</p>
              )}

              <div className="form-group">
                <label>Notes</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    {...register("note")}
                    id="cashdiscount"
                    placeholder="Notes"
                  />
                  <div className="invalid-feedback"></div>
                </div>
              </div> */}

              <div className="form-group">
                <button type="submit" className="btn btn-primary m-2">
                  Add
                </button>
                <button className="btn btn-danger"  onClick={redirect}>
                  Cancel
                </button>
              </div>
            </form>
            }
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default EditParty;
