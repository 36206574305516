import React, { useEffect, useState } from "react";
import $ from "jquery";
import { getMachineList } from "../../api/apiHandler";
import "jquery/dist/jquery.min.js";
import { MdEdit } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import FeatherIcon from "feather-icons-react";
// import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
// import Switch from '@material-ui/core/Switch';
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import { useNavigate, NavLink } from "react-router-dom";
import "jquery/dist/jquery.min.js";
import Sidebar from "../../components/sidebar/sidebar";
import Header from "../../components/header/header";
import "../../components/sidebar/sidebar.css";
import "./MachineCategories";
import MachineCategories from "./MachineCategories";

function MachineList() {
  const navigate = useNavigate();
  const [machineList, setMachineList] = useState(null);

  useEffect(() => {
    Swal.fire({
      title: "Please wait...",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    getMachineList({}).then((response) => {
      console.log(response.data,"machine");
      if (response.code == 200) {
        Swal.close();
        setMachineList(response.data);
      } else {
        Swal.close();
        setMachineList([]);
      }
    });
  }, []);

  useEffect(() => {
    $("#mangeUser").DataTable({
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      order: [[0, 'desc']],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'print'],
      "bDestroy": false,
    });
  }, [machineList]);
  const addMachineDetails = () => {
    navigate(`/addMachine`);
  };
  const editMachineDetails = (item) => {
    navigate(`/machine/edit/${item}`);
  };
  const viewMachineDetail = (item) => {
    navigate(`/machine/view/${item}`);
  };
console.log()
  return (
    <>
      <Sidebar />
      <div className="content-page mt-5">
        <div className="content">
          <div className="container-fluid">
            <MachineCategories />

            <div
              className="col-lg-10 col-md-8 col-sm-6 offset-2"
              style={{ paddingTop: "8px" }}
            >
              <h2 className="offset-1">Machine Details</h2>
              {
                <button className="add" onClick={addMachineDetails}>
                  <span>ADD MACHINE</span>
                </button>
              }
              <table
                class="table"
              >
                <thead>
                  <tr>
                  <th scope="col">#</th>
                    <th scope="col-lg-4">Machine number</th>
                    <th scope="col">Name</th>
                    <th scope="col">Image</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {machineList &&
                    machineList.map((item, index) => (
                      <tr>
                        <th scope="row">{item?.id}</th>
                        <td>{item?.machine_number}</td>
                        <td>{item?.name}</td>
                        <td>
                          <span>
                            <img
                              src="https://eatkingfresh.in/portal/logo.png"
                              alt=""
                              height="50"
                              style={{ padding: "5px" }}
                            />
                          </span>{" "}
                        </td>
                        <td>
                          <span
                            onClick={() => {
                              editMachineDetails(item?.id);
                            }}
                          >
                            <MdEdit />
                          </span>
                          <span
                            onClick={() => {
                              viewMachineDetail(item?.id);
                            }}
                          >
                            {" "}
                            <IoMdEye />{" "}
                          </span>
                          <span
                            onClick={() => {
                              viewMachineDetail(item?.id);
                            }}
                          >
                            <MdDeleteOutline />
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MachineList;
