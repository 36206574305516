import React, { useEffect, useState } from "react";
import { useNavigate,NavLink  } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "../customer/Customer.css";
import Sidebar from '../../components/sidebar/sidebar';
import Header from '../../components/header/header';
import {
  addParty
} from "../../api/apiHandler";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { yupResolver } from '@hookform/resolvers/yup';
// import Select from "react-select";

function AddParty() {
  
  const validationSchema = Yup.object().shape({
    party_name: Yup.string().required("Party name is required"),
    contact_name: Yup.string().required("Contact name is required"),
    gst_number:Yup.string().required("Gst number is required"),
    city: Yup.string().required("City is required"),
    address: Yup.string().required("Address is required"),
    pincode: Yup.string().required("Pincode  is required"),
   
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);

  const { errors } = formState;
  const navigate = useNavigate();

  function redirect() {
    navigate("/party");
  }

  const onSubmit = (data) => {
    addParty({
      party_name: data.party_name,
    contact_name: data.contact_name,
    gst_number:  data.gst_number,
    city:  data.city,
    address:  data.address,
    pincode:  data.pincode
      
    }).then((response) => {
      if (response.code == 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          navigate("/party");
        }, 2000);
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: response.data.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  return (
   <>
    <Sidebar/>
    
    <div className="content-page mt-5 offset-2">
      <div className="content">
        <div className="container-fluid">
          <ToastContainer />
          <div
            className="cal col-xs-2 col-md-6 col-sm-4 col-lg-10"
            style={{ border: "1px solid black" }}
          >
            <h5>Add Party</h5>
            <hr></hr>
            {
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label>GST Number</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      id="gst_number"       
                      {...register("gst_number")}               
                    />
                   <div className="invalid-feedback">
                      {errors.gst_number?.message}
                    </div>
                  </div>
                </div>  
                <div className="form-group">
                  <label> Party Name</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="party_name"       
                      {...register("party_name")}                      
                    />
                    <div className="invalid-feedback">
                    {errors.party_name?.message}
                    </div>
                  </div>
                </div>  
                <div className="form-group">
                  <label> Contact Name</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="contact_name"       
                      {...register("contact_name")}                        
                    />
                    <div className="invalid-feedback">
                    {errors.contact_name?.message}
                    </div>
                  </div>
                </div>            
                <div className="form-group">
                  <label>City</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="city"       
                      {...register("city")}                   
                    />
                    <div className="invalid-feedback">
                    {errors.city?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label> Address</label>
                  <div>
                    <input
                      type="textarea"
                      className="form-control"
                      id="address"
                      {...register("address")}      
                      placeholder="Please enter address"
                    />
                    <div className="invalid-feedback">
                    {errors.address?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label> PinCode</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      id="pincode"  
                      {...register("pincode")}    
                      maxLength={6}                    
                    />
                    <div className="invalid-feedback">
                    {errors.pincode?.message}
                    </div>
                  </div>
                </div>
                {/* <div className="form-group">
                        <label>  Owner Name</label>
                        <div>
                            <input type="text" className="form-control" {...register('owner_name')} placeholder="Please enter owner name" />
                            
														<div className="invalid-feedback">{errors.owner_name?.message}</div>
                        </div>

                    </div>
                    <div className="form-group">
                        <label>  Owner Number</label>
                        <div>
                            <input type="text" className="form-control" id="owner_number" {...register('owner_number')} placeholder="Please enter owner number" />
                            <div className="invalid-feedback"></div>
                        </div><div className="invalid-feedback">{errors.owner_number?.message}</div>
                    </div> */}
                {/* {inputList.map((x, i) => {
                        return (
                        <div className="box">
													<div className="form-group">
															<label>  Owner Name</label>
															<div>
																	<input type="text" className="form-control" {...register('owner_name')} id="owner_name"  name="owner_name"
					placeholder="Owner Name"
									value={x.ownerName}
																		onChange={e => handleInputChange(e, i)}/>
																	<div className="invalid-feedback">{errors.owner_name?.message}</div>
															</div>
													</div>
													<div className="form-group">
															<label>  Owner Number</label>
															<div>
																	<input type="number"  id="owner_number" {...register('owner_number')} className="form-control" 
									name="owner_number"			placeholder="Owner Number"	value={x.owner_number}		onChange={e => handleInputChange(e, i)} />
																	<div className="invalid-feedback">{errors.owner_number?.message}</div>
															</div>
													</div> */}
                {/* <input type="text" className="form-control" 
                            name="ownerName"
                placeholder="Owner Name"
                            value={x.ownerName}
                            onChange={e => handleInputChange(e, i)}
                            />            
                            <input
                            type="text" className="form-control ml10" 
                            name="ownerNumber"
                placeholder="Owner Number"
                            value={x.ownerNumber}
                            onChange={e => handleInputChange(e, i)}
                            /> */}
                {/* <div className="btn-box">
                            {inputList.length !== 1 && <button
                                className="mr10"
                                onClick={() => handleRemoveClick(i)}>Remove</button>}
                            {inputList.length - 1 === i && <button onClick={handleAddClick}>Add</button>}
                            </div>
                        </div>
                        );
                    })} */}
                {/* {ownerNameInputList.map((x, i) => {
                  return (
                    <div className="box">
                      <div className="form-group">
                        <label> Owner Name</label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            required
                            id="owner_name"
                            name="owner_name"
                            placeholder="Owner Name"
                            value={x.owner_name}
                            onChange={(e) => handleInputChange(e, i)}
                            pattern="^[a-zA-Z_ ]*$"
                            title="Numbers not allow"
                          />
                          <div className="invalid-feedback">
                            {errors.owner_name?.message}
                          </div>
                        </div>
                      </div>
                      <div className="btn-box">
                        {ownerNameInputList.length !== 1 && (
                          <button
                            className="mr10 btn btn-primary  waves-light m-2"
                            onClick={() => handleRemoveClick(i)}
                          >
                            Remove
                          </button>
                        )}
                        {ownerNameInputList.length - 1 === i && (
                          <button
                            className=" mr10 btn btn-primary waves-effect waves-light m-2"
                            onClick={handleAddClick}
                          >
                            Add More
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })} */}
                {/* {ownerNumberInputList.map((x, i) => {
                  return (
                    <div className="box">
                      <div className="form-group">
                        <label> Owner Number</label>
                        <div style={{ display: "flex" }} class="input-box ">
                          <span class="prefix">0</span>
                          <input
                            type="tel"
                            maxLength={10}
                            required
                            className="form-control"
                            id="owner_number"
                            name="owner_number"
                            placeholder="Owner Number"
                            value={x.owner_number}
                            pattern="[0-9]+"
                            title="please enter number only"
                            defaulValue=""
                            onChange={(e) => handleNumberInputChange(e, i)}
                          />
                        </div>
                        <div className="invalid-feedback">
                          {errors.owner_number?.message}
                        </div>
                      </div>

                      <div className="btn-box">
                        {ownerNumberInputList.length !== 1 && (
                          <button
                            className="mr10 btn btn-primary waves-effect waves-light m-2"
                            onClick={() => handleNumberRemoveClick(i)}
                          >
                            Remove
                          </button>
                        )}
                        {ownerNumberInputList.length - 1 === i && (
                          <button
                            className=" mr10 btn btn-primary waves-effect waves-light m-2"
                            onClick={handleNumberAddClick}
                          >
                            Add More
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })} */}

                {/* <div className="form-group">
                  <label> Sector </label>
                  <div className="col-md-12 col-sm-12 col-xs-12 select">
                    <select className="form-control" {...register("sector")}>
                      <option selected value="">
                        Select type
                      </option>
                      <option value="Dairy">Dairy</option>
                      <option value="Lime">Lime</option>
                      <option value="Pepsi">Pepsi</option>
                      <option value="Other">Other</option>
                      <option value="Bearing">Bearing</option>
                      <option value="Cables">Cables</option>
                      <option value="Health Care">Health Care</option>
                    </select>
                    <div className="invalid-feedback">
                      {errors.sector?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label> Address</label>
                  <div>
                    <input
                      type="textarea"
                      className="form-control"
                      {...register("address")}
                      id="address"
                      placeholder="Please enter address"
                    />
                    <div className="invalid-feedback">
                      {errors.address?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label> Credit Period(Days)</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      {...register("credit_period")}
                      id="credit_period"
                      placeholder="Credit Period"
                    />
                    <div className="invalid-feedback">
                      {errors.credit_period?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label> Cash Discount(%)</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      {...register("cash_discount")}
                      id="cash_discount"
                      placeholder="Cash Discount"
                    />
                    <div className="invalid-feedback">
                      {errors.cash_discount?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label> Conversion Rate</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      {...register("conversion_rate")}
                      id="conversion_rate"
                      placeholder="Conversation Rate"
                    />
                    <div className="invalid-feedback">
                      {errors.conversion_rate?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label> Designated Transport</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      {...register("designated_transport")}
                      id="designated_transport"
                      placeholder="Designated Transport"
                      pattern="^[a-zA-Z, ]*$"
                      title="Numbers not allow"
                    />
                    <div className="invalid-feedback">
                      {errors.designated_transport?.message}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label> Transport </label>
                  <div className="col-md-12 col-sm-12 col-xs-12 select">
                    <select className="form-control" {...register("transport")}>
                      <option selected value="">
                        Select type
                      </option>
                      <option value="To Pay">To Pay</option>
                      <option value="Paid">Paid</option>
                      <option value="Partial">Partial</option>
                    </select>
                    <div className="invalid-feedback">
                      {errors.transport?.message}
                    </div>
                  </div>
                </div> */}
                {/* <div className="form-group">
                                    <label>Influencer Company Name</label>
                                    {
                                    <div className="col-md-12 col-sm-12 col-xs-12 select">
                                        <select  className="form-control"  {...register('influencer_company_name')} isMulti isSearchable={true} onChange={handleSelectCustomer}>
                                        {
                                            //CustomerDetailsList && CustomerDetailsList.map((item, index) =>
                                                <option  id={item.id} value={item?.company_name}>{item?.company_name}</option>)
                                        }
                                        </select>
                                    </div>
                                    }
                                </div>
																
                     <div className="form-group">
                        <div>
                        {
                          CustomerDetailsId?.owner_name.split(",").map((item1, index)=>    index == 0 &&
                          <input type="text" className="form-control" {...register('influencer_owner_name')} placeholder='Infulencer Owner Name'  readonly value={item1}/>
                         )}
                        </div>
                    </div>
                    <div className="form-group">
                        <div>
                        {
                          CustomerDetailsId?.owner_number.split(",").map((item, index)=>    index == 0 &&
                            <input type="number" className="form-control" {...register('influencer_owner_number')} placeholder='Infulencer Owner number'  readOnly value={item}/>
                            )}
                       
                        </div>
                    </div> */}
                {/* <label>Influencer Company Name</label>
                {CustomerDetailsList != null ? (
                  <Select
                    {...register("influencer_company_name")}
                    options={optionList}
                    placeholder="Select Infulencer Company"
                    value={selectedOptions}
                    onChange={handleSelect}
                    isSearchable={true}
                    isMulti
                  />
                ) : (
                  <p>Company Not found</p>
                )}

                <div className="form-group">
                  <label>Notes</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      {...register("note")}
                      id="cashdiscount"
                      placeholder="Notes"
                    />
                    <div className="invalid-feedback"></div>
                  </div>
                </div> */}

                <div className="form-group">
                  <button type="submit" className="btn btn-primary m-2">
                    Add
                  </button>
                  <button className="btn btn-danger"  onClick={redirect}>
                    Cancel
                  </button>
                </div>
              </form>
            }
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default AddParty;
