import React,{ useEffect, useState } from 'react'
import { useLocation,NavLink } from "react-router-dom";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarSubMenu
} from 'cdbreact';
import "./sidebar.css"
import {getPermission} from '../../api/apiHandler';
import { RiProfileFill } from 'react-icons/ri';

function Sidebar() {
  const [permissionData, setPermissionData] = useState([])

    // useEffect(() => {
    //    console.log(localStorage.getItem("Arole"))
    //     if(localStorage.getItem("Arole") == 'admin'){
    //         var adminPermission = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48']
    //         setPermissionData(adminPermission)
    //     }else{
    //         getPermission({"staff_id":localStorage.getItem("Aid")}).then((res)=>{
    //             if(res.data.code==1){
    //                 console.log(res.data.data[0].permission.split(","))
    //                 setPermissionData(res.data.data[0].permission.split(","))
    //             }
    //         })     
    //     }
    // }, [])
    console.log(permissionData)
    const location = useLocation();

    const [toggle, setToggle] = useState(false);
    const toggler = () => setToggle(!toggle);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
      if (window.innerWidth >= 1200) {
        setToggle(false); // Reset sidebar state on larger screens
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  
    // if ( permissionData.length == 0) return <> </>
  

  return (
    <div style={{ display: 'flex', bottom: '0', overflow: 'scroll initial',position:'absolute',top:"0" }}>
    <CDBSidebar textColor="#fff" backgroundColor="#915454f2!important " style={{ width: isMobile ? (toggle ? '200px' : '0px') : toggle ? '200px' : '0px',
            transition: 'width 0.3s' }} className='col-lg-10 col-md-10 col-sm-4 col-xs-2'  >
      <div onClick={toggler}   >
      <CDBSidebarHeader  prefix={<i className="fa fa-bars fa-large"></i>}>
      <span>
          <img src="https://eatkingfresh.in/portal/logo.png" alt="" height="50" 
          style={{"padding":"5px",
          height : isMobile ? (toggle ? '80px' : '00px') : toggle ? '80px' : '80px',
          width : isMobile ? (toggle ? '80px' : '00px') : toggle ? '80px' : '80px',}}
          />
        <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
         PORTAL
        </a>
      </span>  
      </CDBSidebarHeader>
      </div>

      <CDBSidebarContent className="sidebar-content" style={{
                display: toggle ? 'block' : 'White', // Toggle the visibility
                width: isMobile ? (toggle ? '200px' : '70px') : toggle ? '200px' : '200px',
                height : isMobile ? (toggle ? '80px' : '00px') : toggle ? '80px' : '80px',
                transition: 'display 0.3s',
            }}> 
        <CDBSidebarMenu>
          <NavLink exact to="/dashboard" style={{"font-size":"20px"}} activeClassName="activeClicked">
            <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/machine"  style={{"padding":"5px","font-size":"20px"}} activeClassName="activeClicked">
            <CDBSidebarMenuItem icon="user">Machine</CDBSidebarMenuItem>            
          </NavLink>              
          <NavLink exact to="/party" style={{"padding":"5px","font-size":"20px"}} activeClassName="activeClicked">
            <CDBSidebarMenuItem icon="table">Party</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/addJob " style={{"padding":"5px","font-size":"20px"}} activeClassName="activeClicked">
            <CDBSidebarMenuItem icon="chart-line">Job</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/pendingJob" style={{"padding":"5px","font-size":"20px"}} activeClassName="activeClicked">
            <CDBSidebarMenuItem icon="table">Pending Job</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/completedJob" style={{"padding":"5px","font-size":"20px"}} activeClassName="activeClicked">
            <CDBSidebarMenuItem icon="table">Completed Job</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/profile" style={{"padding":"5px","font-size":"20px"}} activeClassName="activeClicked">
            <CDBSidebarMenuItem icon="table">Profile</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/logout" style={{"padding":"5px","font-size":"20px"}} activeClassName="activeClicked">
            <CDBSidebarMenuItem icon="table">Logout</CDBSidebarMenuItem>
          </NavLink>
          {/* <NavLink exact to="/profile" style={{"padding":"5px","font-size":"20px"}} activeClassName="activeClicked">
            <CDBSidebarMenuItem >
              <RiProfileFill >Profile</RiProfileFill>
            </CDBSidebarMenuItem>
          </NavLink> */}
          
        {/* { permissionData.indexOf('34') != -1 || localStorage.getItem("Arole") == 'admin' ?
          <NavLink exact to="/role/add" activeClassName="activeClicked">
            <CDBSidebarMenuItem icon="table">Add Staff</CDBSidebarMenuItem>
          </NavLink>:<></>
        } */}
           {/*<NavLink exact to="/userRight" activeClassName="activeClicked">
            <CDBSidebarMenuItem icon="table">UserRight</CDBSidebarMenuItem>
          </NavLink> */}
       
          {/* <NavLink exact to="/role" activeClassName="activeClicked">
            <CDBSidebarMenuItem icon="table">Permisson</CDBSidebarMenuItem>
          </NavLink> */}
        </CDBSidebarMenu>
      </CDBSidebarContent>

      <CDBSidebarFooter style={{ textAlign: 'center' }}>
      
      </CDBSidebarFooter>
    </CDBSidebar>
  </div>
  )
}

export default Sidebar