import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  getPartyList
} from "../../api/apiHandler";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import FeatherIcon from "feather-icons-react";
// import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
// import Switch from '@material-ui/core/Switch';
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import { useNavigate, NavLink } from "react-router-dom";
import "jquery/dist/jquery.min.js";
import Sidebar from "../../components/sidebar/sidebar";
import Header from "../../components/header/header";
import "../../components/sidebar/sidebar.css";
import { MdEdit } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { Input } from "reactstrap";

function PartyList() {
  const [permissionData, setPermissionData] = useState([]);

  const navigate = useNavigate();
  const [partyDetailsList, setPartyDetailsList] = useState(null);
 
  useEffect(() => {
    			Swal.fire({
    				title: 'Please wait...',
    				didOpen: () => {
    				Swal.showLoading()
    				}
    			})
    		
    				getPartyList({}).then((response) => {
  
    						console.log(response.data.data)
    						if (response.code == 200) {
    							Swal.close()
                  setPartyDetailsList(response.data)
    						}
    						else
    						{
    							Swal.close()
    							setPartyDetailsList([])
    						}
    				})
    			
    			
       }, [])
  useEffect(() => {
      $('#mangeUser').DataTable(
          {
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true,
            dom: 'Bfrtip',
            buttons: ['copy', 'csv', 'print']
          }
      );
  }, [partyDetailsList])

  const showMessage = (msg) => {
      Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: msg,
          toast: true,
          showConfirmButton: false,
          timer: 1500
      })
  }
  const addPartyDetails = () => {
    navigate(`/addParty`);
  };
  const editPartyDetails = (item) => {
    navigate(`/party/edit/${item}`);
  };
  const viewPartyDetails = (item) => {
    navigate(`/party/view/${item}`);
  };

  // function changeStatus(categoryId, item, position)
  // 	{
  // 		Swal.fire({
  // 			title: 'Are you sure you want to Change Status?',
  // 			text: "You won't be able to revert this!",
  // 			icon: 'question',
  // 			showCancelButton: true,
  // 			confirmButtonColor: '#02C9D6',
  // 			cancelButtonColor: '#d33',
  // 			confirmButtonText: 'Yes, change it!'
  // 			}).then((result) => {
  // 			if (result.isConfirmed) {
  // 					console.log("categoryId " + categoryId)
  // 					var status = (item.status === "Active") ? "Inactive" : "Active"
  // 						console.log(status)
  // 								changeCustomerStatus({ "category_id": categoryId, "status": status }).then((response) => {
  // 														console.log(response)
  // 										if (response.data.code == 1) {
  // 												showMessage(response.data.message)
  // 												CustomerDetailsList[position].status = status
  // 												var data = renderToStaticMarkup(renderStatusUiText(CustomerDetailsList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
  // 												$('#mangeUser').DataTable().row("#customerDetailId" + categoryId).cell("#customerDetailStatus" + categoryId).data(data).draw(false);

  // 										}
  // 								});
  // 			}
  // 		})
  // 	}
  // function renderStatusUiText(item) {
  //     return (
  //         item.status == "Active" ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
  //     )
  // }

  // if (CustomerDetailsList == null) return <> </>

  return (
    <>
      <Sidebar />
      <div className="content-page mt-5">
        <div className="content">
          <div className="container-fluid">
            <div
              className="col-lg-10 col-md-8 col-sm-6 offset-2 offset-2"
              style={{ paddingTop: "8px" }}
            >
              <h2 className="offset-1">Party Details</h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  float:"right",
                  marginRight: "135px"
                }}
              >
               
                <div className="button">
                  {
                    <button
                      className="add"
                      style={{ marginRight: "0%" }}
                      onClick={addPartyDetails}
                    >
                      <span>Add Party</span>
                    </button>
                  }
                </div>
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col-lg-4">GST No.</th>
                    <th scope="col-lg-4">Party Name</th>
                    <th scope="col">Contact Name</th>
                    <th scope="col">Contact Number</th>
                    <th scope="col">City</th>
                    <th scope="col">Address</th>
                    <th scope="col">PinCode</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                {
														partyDetailsList && partyDetailsList.map((item, index) =>
                  <tr>
                    <td >{item?.id}</td>
                    <td>{item?.gst_number}</td>
                    <td>{item?.party_name}</td>
                    <td>{item?.contact_name}</td>
                    <td>{item?.gst_number}</td>
                    <td>{item?.city}</td>
                    <td>{item?.address}</td>
                    <td>{item?.pincode}</td>
                    <td>
                      <span
                        onClick={() => {
                          editPartyDetails(item?.id);
                        }}
                      >
                        <MdEdit />
                      </span>
                      <span
                        onClick={() => {
                          viewPartyDetails(item?.id);
                        }}
                      >
                        {" "}
                        <IoMdEye />{" "}
                      </span>
                      <span
                        onClick={() => {
                          viewPartyDetails(1);
                        }}
                      >
                        <MdDeleteOutline />
                      </span>
                    </td>
                  </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PartyList;
