import React,{ useEffect} from 'react'
import { logout } from '../../../api/apiHandler';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logOutRedirectCall } from '../../common/RedirectPathMange';

function Logout() {
    useEffect(()=>{
        logout({}).then((response)=>{
            if(response.data.code == 1){ 
                logOutRedirectCall();
            }
        })
    },[])   
    return (
        <>
        </>
    )
}

export default Logout;