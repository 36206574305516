import React, { useEffect, useState } from "react";
import {
  dashboard,
  filterPendingOrders,
  filterCompletedOrders,
  getPayment,
  getOrder,
  dashboardSearchCustomer,
  getCustomer,
} from "../../api/apiHandler";
import "../dashboard/Dashboard.css";
import "jquery/dist/jquery.min.js";
import $ from "jquery";
import Sidebar from '../../components/sidebar/sidebar';
import Header from '../../components/header/header';
import '../../components/sidebar/sidebar.css';
import './machine.css';
import 'react-toastify/dist/ReactToastify.css';
// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
// import "datatables.net-buttons/js/dataTables.buttons.js";
// import "datatables.net-buttons/js/buttons.colVis.js";
// import "datatables.net-buttons/js/buttons.flash.js";
// import "datatables.net-buttons/js/buttons.html5.js";
// import "datatables.net-buttons/js/buttons.print.js";
// import Select from "react-select";

function MachineCategories() {
  const [CustomerDetailsList, setCustomerDetailsList] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState();
  const [customerCount, setCustomerCount] = useState("-");
  const [OrderDetailsList, setOrderDetailsList] = useState(null);
  const [PaymentDetailsList, setPaymentDetailsList] = useState(null);
  const [TotalPendingOrders, setTotalPendingOrders] = useState(null);
  const [TotalCompleteOrders, setTotalCompleteOrders] = useState(null);
  const [FilterPendingOrders, setFilterPendingOrders] = useState(null);
  const [FiltercompletedOrders, setFiltercompletedOrders] = useState(null);
  const [CustomerDetail, setCustomerDetail] = useState(null);
  const [SearchCustomer, setSearchCustomer] = useState(null);


  // Function triggered on selection
  function handleSelect(data) {
    console.log(data);

    setSearchCustomer(data.value);
  }
  // 	useEffect(() => {
  // 		$('#mangeUser').DataTable(
  // 				{
  // 						pagingType: 'full_numbers',
  // 						pageLength: 5,
  // 						processing: true,
  // 						order: [[0, 'desc']],
  // 						dom: 'Bfrtip',
  // 						"bDestroy": true,
  // 				}
  // 		);
  // }, [OrderDetailsList])
  // useEffect(() => {
  //   filterPendingOrders({}).then((response) => {
  //     console.log(response.data.code);
  //     if (response.data.code == 1) {
  //       setFilterPendingOrders(response.data.data);
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   filterCompletedOrders({}).then((response) => {
  //     console.log(response.data.code);
  //     if (response.data.code == 1) {
  //       setFiltercompletedOrders(response.data.data);
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   getOrder({}).then((response) => {
  //     console.log(response.data.code);
  //     var totalpendingorders = [];
  //     var totalCompleteorders = [];
  //     if (response.data.code == 1) {
  //       setOrderDetailsList(response.data.data);
  //       //response.data.data != null &&
  //       // response.data.data.map(
  //       //   (item, index) =>
  //       //   {
  //       //     if(item?.order_status == "pending")
  //       //     {
  //       //       totalpendingorders.push(item?.order_number)
  //       //     }
  //       //     else
  //       //     {
  //       //       totalpendingorders.push([])
  //       //     }

  //       //   }
  //       // );
  //       // setTotalPendingOrders(totalpendingorders.length);
  //     }
  //   });
  // }, []);
  // console.log(TotalCompleteOrders);
  // console.log(FilterPendingOrders);
  // useEffect(() => {
  //   getPayment({}).then((response) => {
  //     console.log(response.data.code);
  //     if (response.data.code == 1) {
  //       setPaymentDetailsList(response.data.data);
  //     }
  //   });
  // }, []);
  // function handleCustomerSearch(event) {
  //   console.log("color");
  //   const value = event.target.value;
  //   setSearchCustomer(value);
  // }
  // useEffect(() => {
  //   getCustomer({}).then((response) => {
  //     console.log(response.data.code);
  //     if (response.data.code == 1) {
  //       setCustomerDetailsList(response.data.data);
  //     }
  //   });
  // }, []);
  // console.log(CustomerDetailsList);
  // const optionList =
  //   CustomerDetailsList &&
  //   CustomerDetailsList.map((item, index) =>
  //     item?.status != "Inactive"
  //       ? { value: item?.company_name, label: item?.company_name, id: item?.id }
  //       : {
  //           value: "Company Not found",
  //           label: "Company Not found",
  //           id: "Company Not found",
  //         }
  //   );
  // console.log(optionList);
  // console.log(selectedOptions);
  // useEffect(() => {
  //   console.log(SearchCustomer);
  //   dashboardSearchCustomer({ company_name: SearchCustomer }).then(
  //     (response) => {
  //       console.log(response.data.code);
  //       if (response.data.code == 1) {
  //         setCustomerDetail(response.data.data);
  //       }
  //     }
  //   );
  // }, [SearchCustomer]);
  return (
    <>
      <main>
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="dashboard">
                <div className="col-lg-8  col-md-6 col-sm-4 offset-2" style={{padding:'20px'}}>
                  <h3>Machine categories</h3>
                  <div className="row">
                    <div className="col-lg-4 col-md-6" >
                      <div className="card  bg-primary">
                        <div className="card-body mini-stat-img"style={{backgroundColor: "none!important"}} >
                          <div className="mini-stat-icon">
                            <i className="mdi mdi-account-multiple float-right"></i>
                          </div>
                          <div className="text-white">
                            <h6 className="text-uppercase mb-3">Corrugation-6</h6>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="card  bg-primary">
                        <div className="card-body mini-stat-img">
                          <div className="mini-stat-icon">
                            <i className="mdi mdi-buffer float-right"></i>
                          </div>
                          <div className="text-white">
                            <h6 className="text-uppercase mb-3">
                            Punching-3
                            </h6>                                                     
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="card  bg-primary">
                        <div className="card-body mini-stat-img">
                          <div className="mini-stat-icon">
                            <i className="mdi mdi-buffer float-right"></i>
                          </div>
                          <div className="text-white">
                            <h6 className="text-uppercase mb-3">
                              Pasting-3
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6">
                                          <div className="card mini-stat bg-primary">
                                              <div className="card-body mini-stat-img">
                                                  <div className="mini-stat-icon">
                                                      <i className="mdi mdi-tag-text-outline float-right"></i>
                                                  </div>
                                                  <div className="text-white">
                                                      <h6 className="text-uppercase mb-3">Completed Quotes</h6>
                                                      <h4 className="mb-4">30</h4>
                                                  </div>
                                              </div>
                                          </div>
                                      </div> */}
                  </div>
                  <div className="row">                    
                    <div className="col-lg-4 col-md-6">
                      <div className="card  bg-primary">
                        <div className="card-body mini-stat-img">
                          <div className="mini-stat-icon">
                            <i className="mdi mdi-buffer float-right"></i>
                          </div>
                          <div className="text-white">
                            <h6 className="text-uppercase mb-3">
                            Glue-3
                            </h6>                                                     
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="card  bg-primary">
                        <div className="card-body mini-stat-img">
                          <div className="mini-stat-icon">
                            <i className="mdi mdi-buffer float-right"></i>
                          </div>
                          <div className="text-white">
                            <h6 className="text-uppercase mb-3">
                              Teaching-3
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6">
                                          <div className="card mini-stat bg-primary">
                                              <div className="card-body mini-stat-img">
                                                  <div className="mini-stat-icon">
                                                      <i className="mdi mdi-tag-text-outline float-right"></i>
                                                  </div>
                                                  <div className="text-white">
                                                      <h6 className="text-uppercase mb-3">Completed Quotes</h6>
                                                      <h4 className="mb-4">30</h4>
                                                  </div>
                                              </div>
                                          </div>
                                      </div> */}
                  </div>
                </div>
                
               
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default MachineCategories;
