import axios from "axios";
import {logOutRedirectCall} from '../pages/common/RedirectPathMange'



const showMessage = (msg) => {
  // console.log(msg)
}


const axiosClient = axios.create({
  // baseURL: "http://localhost:3016/server/v1/admin/",
   baseURL: "https://rajbaipackprint.com:8081/api/v1/admin/",
   headers: {
     "api-key":
       "jjBlpctTfnPAdb673zDGYpHGFotFhSk86zB8zQ8Bax4=",
     "accept-language": "en",
     "Content-Type": "application/json",
   },
 });

// Body Encryption Request
axiosClient.interceptors.request.use(function (request) {
  request.data = request.data
   
    if(localStorage.getItem("Atoken") !== undefined || localStorage.getItem("Atoken") !== null){
      request.headers['token']=localStorage.getItem("Atoken")
    }
    
  return request;
});
  
axiosClient.interceptors.response.use(
  function (response) {
    response = response.data;

    if(response.status==400){
      showMessage(response.data.message)
    }
    return response;
  }, 
  function (error) {
    let res = error.response;
   
    if (res.status == 401) {
      logOutRedirectCall()
    }else if(res.status == 400 || res.status == 500 || res.status == 404){
      const response = res.data;
      return response 
    }else{
      console.error("Looks like there was a problem. Status Code: " + res.status);
      return Promise.reject(error);
    }
    
  }
);  



export {axiosClient};