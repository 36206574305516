import React from "react";
import Toast from "react-bootstrap/Toast";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { login } from "../../../api/apiHandler";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { v4 as uuid } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginStoreData } from "../../common/RedirectPathMange";

function Login() {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
      email: Yup.string()
          .email('Email address is invalid')
          .required('Email address is required'),
      password: Yup.string()
          .required('Password is required')
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

      const onSubmit = (data) => {
          const unique_id = uuid();
          const small_id = unique_id.slice(0,8)
          var newData = {
              "email": data.email,
              "password": data.password
          }
          login({"email":data.email,"password":data.password}).then((response) => {
  console.log("rrrlogin",response)
              if (response.code == 200) {
                  loginStoreData(response.data)
                  navigate("/dashboard");
                  // let path= window.location.protocol+"//"+window.location.host+"/portal/dashboard"
                  // window.location.href = path;
              }
              else if(response.code == 400) {
                alert("okokoi")
              }else{
                alert("kkkkkk")
                  toast.error(response.message, {
                      position: "top-center",
                      autoClose: 2000,
                      closeOnClick: true,
                      pauseOnHover: true,
                  });
              }
          });
      }
  const handleForgotPassword = () => {
    navigate("/forgotPassword");
  };

  function redirect() {
    navigate("/dashboard");
  }
  return (
    <>
      <div className="wrapper-page">
        <h3 className="text-center m-0">
          <Link to="/" className="logo logo-admin">
            <img
              src="https://eatkingfresh.in/portal/logo.png"
              height="250"
              alt="logo"
            />
          </Link>
        </h3>
        <div className="card login">
          <div className="card-body">
            <div className="p-2">
              <h4 className="font-18 m-b-5 text-center">Welcome !</h4>
              <p className="text-center">Sign in to continue to PORTAL.</p>
              <ToastContainer />
              <form className="col-lg-6 col-md-4 offset-3 form-horizontal m-t-30" onSubmit={handleSubmit(onSubmit)}>

<div className="form-group">
    <label htmlFor="email">Email</label>
    <input type="email" {...register('email')} className="form-control" id="email" placeholder="Enter email" />
    <div className="invalid-feedback">{errors.email?.message}</div>
</div>

<div className="form-group">
    <label htmlFor="userpassword">Password</label>
    <input type="password" {...register('password')} className="form-control" id="userpassword" placeholder="Enter password" />
    <div className="invalid-feedback">{errors.password?.message}</div>
</div>

<div className="form-group row m-t-20">

    <div className="col-12 text-center mt-4">
        <button className="btn btn-primary w-md waves-effect waves-light" type="submit" style={{borderRadius:"20px"}}>Login</button>
        {/* <Link className="btn btn-primary w-md waves-effect waves-light" type="submit">Login</Link> */}
    </div>
  
    
    <div className="col-12  text-center">
        <Link to="/forgotPassword" className="text-muted btn btn-link mt-4"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
    </div>
    
</div>

</form>
              {/* <form className="col-lg-6 col-md-4 offset-3 form-horizontal m-t-30" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label htmlFor="email" color="white">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="userpassword" color="white">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="userpassword"
                    placeholder="Enter password"
                  />
                </div>

                <div className="form-group row m-t-20">
                  <div className="col-12 text-center">
                  <button className="btn btn-primary w-md waves-effect waves-light" type="submit" style={{borderRadius:"20px"}}>Login</button>
                    {/* <Link className="btn btn-primary w-md waves-effect waves-light" type="submit">Login</Link> */}
                  {/* </div> */}

                  {/* <div className="col-12  text-center">
                                    <Link to="/forgotPassword" className="text-muted btn btn-link mt-4"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                                </div> */}
                {/* </div>
              </form> */} 
            </div>
          </div>
        </div>
        {/* <div className="m-t-40 text-center">
                <p>© {new Date().getFullYear()} ISL Cargo. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
            </div> */}
      </div>
    </>
  );
}

export default Login;
