import React, { useEffect, useState } from 'react'
import $ from "jquery";
import { getCustomer,changeCustomerStatus,getPermission } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import FeatherIcon from "feather-icons-react";
// import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
// import Switch from '@material-ui/core/Switch';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate ,NavLink } from 'react-router-dom';
import "jquery/dist/jquery.min.js";
import Sidebar from '../../components/sidebar/sidebar';
import Header from '../../components/header/header';
import '../../components/sidebar/sidebar.css';
import { MdEdit } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";

function JobList() {
	const [permissionData, setPermissionData] = useState([])
	
    const navigate = useNavigate();
    const [CustomerDetailsList, setCustomerDetailsList] = useState(null)
	// 	useEffect(() => {
	// 		console.log("admin",localStorage.getItem("AisLogin")  )     
	// 			if(localStorage.getItem("Arole") == 'admin'){
	// 					var adminPermission = ['1','2','3','4','5']
	// 					setPermissionData(adminPermission)
	// 			}else{
	// 					getPermission({"staff_id":localStorage.getItem("Aid")}).then((res)=>{
	// 							if(res.data.code==1){
	// 									setPermissionData(res.data.data[0].permission.split(","))
	// 							}
	// 					})     
	// 			}
	//   }, [])

	// 	useEffect(() => {
	// 		if(permissionData.length > 0) {
	// 				if(permissionData.indexOf('1') == -1 ){
	// 						navigate(`/`);
	// 				}
	// 		}
	// }, [permissionData])
	// 	console.log(permissionData)
	// 		useEffect(() => {
	// 			Swal.fire({
	// 				title: 'Please wait...',
	// 				didOpen: () => {
	// 				Swal.showLoading()
	// 				}
	// 			})
	// 		//console.log(permissionData.indexOf('1'))
	// 		  if(permissionData.length > 0) {				
	// 				console.log(permissionData.indexOf('1'))
	// 			if(permissionData.indexOf('1') != -1 ){
	// 				getCustomer({}).then((response) => {
					
	// 						console.log(response.data.code)
	// 						if (response.data.code == 1) {
	// 							Swal.close()
	// 								setCustomerDetailsList(response.data.data)
	// 						}
	// 						else
	// 						{
	// 							Swal.close()
	// 							setCustomerDetailsList([])
	// 						}
	// 				})
	// 			}else if( permissionData.indexOf('2') != -1 ){
	// 				Swal.close()
	// 				setCustomerDetailsList([])
	// 			}else{
	// 				//Swal.close()
	// 				Swal.fire({
	// 					position: 'top-end',
	// 					icon: 'error',
	// 					title: "You Don’t Have Permission To Access on This Area",
	// 					toast: true,
	// 					showConfirmButton: false,
	// 					timer: 2000
	// 				})
	// 				navigate(`/`);
	// 			}
	// 	    }
    //    }, [permissionData])
    // useEffect(() => {
    //     $('#mangeUser').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //             pageLength: 10,
    //             processing: true,
    //             order: [[0, 'desc']],
    //             dom: 'Bfrtip',
    //             buttons: ['copy', 'csv', 'print'],
    //             "bDestroy": true,
    //         }
    //     );
    // }, [CustomerDetailsList])

    // const showMessage = (msg) => {
    //     Swal.fire({
    //         position: 'top-end',
    //         icon: 'success',
    //         title: msg,
    //         toast: true,
    //         showConfirmButton: false,
    //         timer: 1500
    //     })
    // } 
		const addJobDetails = () => {
			navigate(`/addJob`);
	  }	
		const editJobDetails = (item) => {
				navigate(`/job/edit/${item}`);
		}
		const viewJobDetails = (item) => {
				navigate(`/job/view/${item}`);
		}

    // function changeStatus(categoryId, item, position) 
	// 	{
	// 		Swal.fire({
	// 			title: 'Are you sure you want to Change Status?',
	// 			text: "You won't be able to revert this!",
	// 			icon: 'question',
	// 			showCancelButton: true,
	// 			confirmButtonColor: '#02C9D6',
	// 			cancelButtonColor: '#d33',
	// 			confirmButtonText: 'Yes, change it!'
	// 			}).then((result) => {
	// 			if (result.isConfirmed) {
	// 					console.log("categoryId " + categoryId)
	// 					var status = (item.status === "Active") ? "Inactive" : "Active"
	// 						console.log(status)
	// 								changeCustomerStatus({ "category_id": categoryId, "status": status }).then((response) => {
	// 														console.log(response)
	// 										if (response.data.code == 1) {
	// 												showMessage(response.data.message)
	// 												CustomerDetailsList[position].status = status
	// 												var data = renderToStaticMarkup(renderStatusUiText(CustomerDetailsList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
	// 												$('#mangeUser').DataTable().row("#customerDetailId" + categoryId).cell("#customerDetailStatus" + categoryId).data(data).draw(false);
					
	// 										}
	// 								}); 
	// 			}
	// 		})
	// 	}
    // function renderStatusUiText(item) {
    //     return (
    //         item.status == "Active" ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
    //     )
    // }

		// if (CustomerDetailsList == null) return <> </>

  return (
    <>
    <Sidebar/>
			<div className='content-page mt-5'>
				<div className='content'>
						<div className='container-fluid'>
								
								<div className='col-lg-10 col-md-8 col-sm-6 offset-2 offset-2' style={{paddingTop:"8px"}} >
								<h2 className="offset-1" >Job Details</h2>
								{
									<button className='add' onClick={addJobDetails}><span>Add Job</span></button>
									}
									<table class="table">
  <thead>
    <tr>      
      <th scope="col">#</th>
      <th scope="col-lg-4">Party Name</th>
      <th scope="col">Job Name</th>
      <th scope="col">Sheet Size</th>
	  <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td>Otto</td>
      <td>23/45</td>
	  <td>
       <span  onClick={() => { editJobDetails(1) }}><MdEdit /></span> 
       <span  onClick={() => { viewJobDetails(1) }}> < IoMdEye/> </span>
       <span  onClick={() => { viewJobDetails(1) }}><MdDeleteOutline /></span>
</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>23/45</td>
	  <td>
       <span  onClick={() => { editJobDetails(1) }}><MdEdit /></span> 
       <span  onClick={() => { viewJobDetails(1) }}> < IoMdEye/> </span>
       <span  onClick={() => { viewJobDetails(1) }}><MdDeleteOutline /></span>
</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>23/45</td>
	  <td>
       <span  onClick={() => { editJobDetails(1) }}><MdEdit /></span> 
       <span  onClick={() => { viewJobDetails(1) }}> < IoMdEye/> </span>
       <span  onClick={() => { viewJobDetails(1) }}><MdDeleteOutline /></span>
</td>
    </tr>
  </tbody>
</table>
								</div>
						</div>
				</div>
			</div>
    </>
  )
}

export default JobList